import React, { useState, useEffect } from "react"
import { Link, useNavigate, useParams } from "react-router-dom"

//import components
import Breadcrumbs from "../../components/Common/Breadcrumb"

//react form
import { FormProvider, useForm, Controller } from "react-hook-form"

//validation
import * as yup from "yup"
import { yupResolver } from "@hookform/resolvers/yup"

//redux
import { useSelector, useDispatch } from "react-redux"
import { createSelector } from "reselect"
import Select from "react-select"

import Switch from "react-switch"
import { OnSymbol, Offsymbol } from "helpers/switch_helper"

import {
  Button,
  Card,
  CardBody,
  CardText,
  CardSubtitle,
  CardTitle,
  Col,
  Container,
  Form,
  Input,
  Label,
  Row,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Alert,
  FormFeedback,
} from "reactstrap"
import Flatpickr from "react-flatpickr"
import classnames from "classnames"
import {
  addNewPatient as onAddNewPatient,
  updatePatient as onUpdatePatient,
  getPatientDetail as onGetPatientDetail,
  getTitles as onGetTitles,
} from "store/actions"
import { isEmpty, sample } from "lodash"
import GenderField from "components/Common/Gender/Gender"
import EmptyContainer from "components/Common/EmptyContainer"
import { useDeepCompareEffect } from "hooks"
import { ToastContainer } from "react-toastify"
import Spinners from "components/Common/Spinner"
import LocationField from "components/Common/Location/Location"

function PatientAddEdit() {
  const navigate = useNavigate()
  const routeParams = useParams()
  const dispatch = useDispatch()
  const [isLoading, setLoading] = useState(false)
  const [header, setHeader] = useState("")
  const { id } = routeParams
  /**
   * Form Validation Schema
   */
  const schema = yup.object().shape({
    Titles: yup.object().required("Required"),
    FirstName: yup
      .string()
      .required("Required")
      .min(3, "First name must be at least 3 characters"),
    LastName: yup.string().required("Required"),
    Email: yup.string().required("Required").email("Must be a valid Email"),
    PhoneNumber: yup
      .string()
      .min(0, "Should be between 0 and 10")
      .max(10, "Should be between 0 and 10"),
    Genders: yup.object().required("Required"),
    Address: yup.string().required("Required"),
    Countries: yup.object(),
    States: yup.object(),
    Cities: yup.object().required("Required"),
    PostCode: yup.string().max(10, "Zipcode should be max 10 characters"),
    Active: yup.bool(),
  })

  const formdefault = {
    FirstName: "",
    LastName: "",
    Address: "",
    PhoneNumber: "",
    Email: "",
  }
  const methods = useForm({
    mode: "onChange",
    defaultValues: formdefault,
    resolver: yupResolver(schema),
  })
  const { reset, control, formState, setValue, getValues, trigger } = methods
  const { errors, isValid } = formState

  useDeepCompareEffect(() => {
    dispatch(onGetTitles())
    dispatch(onGetPatientDetail(id))
  }, [dispatch])

  const { patient, loading } = useSelector(state => state.patient)
  const { titles } = useSelector(state => state.staff)

  useEffect(() => {
    if (id === "new") {
      setHeader("Add Patient")
      reset(formdefault)
    } else {
      setHeader("Edit Patient")
      reset(patient)
    }
  }, [id, patient])

  const handleSave = () => {
    trigger() //for form validation
    if (isValid) {
      const { id } = routeParams

      if (id === "new") {
        dispatch(onAddNewPatient(getValues()))
      } else {
        dispatch(onUpdatePatient(getValues()))
      }
      navigate("/patients")
    }
  }

  const onCancelClick = () => {
    navigate("/patients")
  }

  if (isLoading) {
    return <Spinners setLoading={setLoading} />
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Patients" breadcrumbItem={header} />
          {routeParams.id !== "new" && isEmpty(patient) ? (
            <EmptyContainer
              backURL="/patients"
              message="There is no such patient!"
              linkText="Go to Patients Page"
            />
          ) : (
            <Row>
              <Col xs="12">
                <Card>
                  <CardBody>
                    <CardTitle>Basic Information</CardTitle>
                    <p className="card-title-desc mb-4">
                      Fill all information below
                    </p>
                    <FormProvider {...methods}>
                      <Row>
                        <Col sm="4">
                          <div className="mb-3">
                            <label className="form-label">Title</label>
                            <Controller
                              name="Titles"
                              control={control}
                              render={({ field }) => (
                                <>
                                  <Select
                                    {...field}
                                    id="Titles"
                                    options={titles}
                                    getOptionLabel={option => option.Title}
                                    getOptionValue={option => option.TitleID}
                                    required
                                    aria-invalid={!!errors.Titles}
                                    classNamePrefix="select2-selection"
                                  />
                                  {errors?.Titles?.message ? (
                                    <FormFeedback
                                      type="invalid"
                                      className="d-block"
                                    >
                                      {errors?.Titles?.message}
                                    </FormFeedback>
                                  ) : null}
                                </>
                              )}
                            />
                          </div>
                          <div className="mb-3">
                            <Label>Email</Label>
                            <Controller
                              name="Email"
                              control={control}
                              render={({ field }) => (
                                <>
                                  <Input
                                    {...field}
                                    id="Email"
                                    type="email"
                                    required
                                    invalid={!!errors.Email}
                                  />
                                  {errors?.Email?.message ? (
                                    <FormFeedback
                                      type="invalid"
                                      className="d-block"
                                    >
                                      {errors?.Email?.message}
                                    </FormFeedback>
                                  ) : null}
                                </>
                              )}
                            />
                          </div>
                          <div className="mb-3">
                            <Label>First Name</Label>
                            <Controller
                              name="FirstName"
                              control={control}
                              render={({ field }) => (
                                <>
                                  <Input
                                    {...field}
                                    id="FirstName"
                                    type="text"
                                    required
                                    invalid={!!errors.FirstName}
                                  />
                                  {errors?.FirstName?.message ? (
                                    <FormFeedback
                                      type="invalid"
                                      className="d-block"
                                    >
                                      {errors?.FirstName?.message}
                                    </FormFeedback>
                                  ) : null}
                                </>
                              )}
                            />
                          </div>
                          <div className="mb-3">
                            <Label>Last Name</Label>
                            <Controller
                              name="LastName"
                              control={control}
                              render={({ field }) => (
                                <>
                                  <Input
                                    {...field}
                                    id="LastName"
                                    type="text"
                                    required
                                    invalid={!!errors.LastName}
                                  />
                                  {errors?.LastName?.message ? (
                                    <FormFeedback
                                      type="invalid"
                                      className="d-block"
                                    >
                                      {errors?.LastName?.message}
                                    </FormFeedback>
                                  ) : null}
                                </>
                              )}
                            />
                          </div>
                        </Col>
                        <Col sm="4">
                          <div className="mb-3">
                            <Label>Phone Number</Label>
                            <Controller
                              name="PhoneNumber"
                              control={control}
                              render={({ field }) => (
                                <>
                                  <Input
                                    {...field}
                                    id="PhoneNumber"
                                    type="number"
                                    invalid={!!errors.PhoneNumber}
                                  />
                                  {errors?.PhoneNumber?.message ? (
                                    <FormFeedback
                                      type="invalid"
                                      className="d-block"
                                    >
                                      {errors?.PhoneNumber?.message}
                                    </FormFeedback>
                                  ) : null}
                                </>
                              )}
                            />
                          </div>
                          <GenderField />
                          <div className="mb-3">
                            <Label>Active</Label>
                            <div>
                              <Controller
                                name="Active"
                                control={control}
                                render={({ field }) => (
                                  <>
                                    <Switch
                                      {...field}
                                      id="Active"
                                      checked={field.value}
                                      uncheckedIcon={<Offsymbol />}
                                      checkedIcon={<OnSymbol />}
                                      className="me-1 mb-sm-8 mb-2"
                                      onColor="#626ed4"
                                    />
                                  </>
                                )}
                              />
                            </div>
                          </div>
                        </Col>
                        <Col sm="4">
                          <LocationField />
                        </Col>
                      </Row>

                      <div className="d-flex flex-wrap gap-2">
                        <Button
                          color="success"
                          className="btn"
                          onClick={handleSave}
                        >
                          {routeParams.id === "new" ? "Save" : "Update"}
                        </Button>
                        <Button
                          type="submit"
                          color="secondary"
                          onClick={onCancelClick}
                          className=""
                        >
                          Cancel
                        </Button>
                      </div>
                    </FormProvider>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          )}
        </Container>
      </div>
    </React.Fragment>
  )
}

export default PatientAddEdit
