/*Get OBSERVATION */
export const GET_OBSERVATIONS = "GET_OBSERVATIONS"
export const GET_OBSERVATIONS_SUCCESS = "GET_OBSERVATIONS_SUCCESS"
export const GET_OBSERVATIONS_FAIL = "GET_OBSERVATIONS_FAIL"

/**
 * Get OBSERVATION DETAILS
 */
export const GET_OBSERVATION_DETAIL = "GET_OBSERVATION_DETAIL"
export const GET_OBSERVATION_DETAIL_SUCCESS = "GET_OBSERVATION_DETAIL_SUCCESS"
export const GET_OBSERVATION_DETAIL_FAIL = "GET_OBSERVATION_DETAIL_FAIL"

/**
 * Delete OBSERVATION
 */
export const DELETE_OBSERVATION = "DELETE_OBSERVATION"
export const DELETE_OBSERVATION_SUCCESS = "DELETE_OBSERVATION_SUCCESS"
export const DELETE_OBSERVATION_FAIL = "DELETE_OBSERVATION_FAIL"

/**
 * Add OBSERVATION
 */
export const ADD_NEW_OBSERVATION = "ADD_NEW_OBSERVATION"
export const ADD_OBSERVATION_SUCCESS = "ADD_OBSERVATION_SUCCESS"
export const ADD_OBSERVATION_FAIL = "ADD_OBSERVATION_FAIL"

/**
 * Update OBSERVATION
 */
export const UPDATE_OBSERVATION = "UPDATE_OBSERVATION"
export const UPDATE_OBSERVATION_SUCCESS = "UPDATE_OBSERVATION_SUCCESS"
export const UPDATE_OBSERVATION_FAIL = "UPDATE_OBSERVATION_FAIL"
