import React from "react"
import { Navigate } from "react-router-dom"

// Profile
import UserProfile from "../pages/Authentication/user-profile"

//Staff Pages
import Staffs from "../pages/Staff/index"
import StaffAddEdit from "../pages/Staff/StaffAddEdit"

// Authentication related pages
import Login from "../pages/Authentication/Login"
import Logout from "../pages/Authentication/Logout"
import Register from "../pages/Authentication/Register"
import ForgetPwd from "../pages/Authentication/ForgetPassword"

// Dashboard
import Dashboard from "../pages/Dashboard/index"

//404 Page
import Pages404 from "../pages/Utility/404"

//Patient
import Patient from "pages/Patient/index"
import PatientAddEdit from "pages/Patient/PatientAddEdit"

//Observation
import Observation from "pages/Observation/index"
import ObservationAddEdit from "pages/Observation/ObservationAddEdit"
import ObservationReport from "pages/Observation/ObservationReport"

const authProtectedRoutes = [
  { path: "/dashboard", component: <Dashboard /> },

  //Staff
  { path: "/users", component: <Staffs /> },
  { path: "/user/:id", component: <StaffAddEdit /> },

  //Patient
  { path: "/patients", component: <Patient /> },
  { path: "/patient/:id", component: <PatientAddEdit /> },

  //Observation
  { path: "/observations", component: <Observation /> },
  { path: "/observation/:id", component: <ObservationAddEdit /> },
  { path: "/observation-report/:id", component: <ObservationReport /> },

  // this route should be at the end of all other routes
  // eslint-disable-next-line react/display-name
  {
    path: "/",
    exact: true,
    component: <Navigate to="/dashboard" />,
  },

  //404 Page
  { path: "*", component: <Pages404 /> },
]

const publicRoutes = [
  { path: "/login", component: <Login /> },
  { path: "/logout", component: <Logout /> },
  { path: "/forgot-password", component: <ForgetPwd /> },
  { path: "/register", component: <Register /> },
]

export { authProtectedRoutes, publicRoutes }
