import {
  GET_PROFILE,
  GET_PROFILE_SUCCESS,
  GET_PROFILE_FAIL,
} from "./actionTypes"

export const getProfile = rowID => ({
  type: GET_PROFILE,
  payload: rowID,
})

export const getProfileSuccess = profile => ({
  type: GET_PROFILE_SUCCESS,
  payload: profile,
})

export const getProfileFail = error => ({
  type: GET_PROFILE_FAIL,
  payload: error,
})
