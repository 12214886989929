import React, { useState, useEffect, useRef } from "react"
import { Link, useNavigate, useParams } from "react-router-dom"

//import components
import Breadcrumbs from "../../components/Common/Breadcrumb"

//react form
import {
  FormProvider,
  useForm,
  Controller,
  useFieldArray,
} from "react-hook-form"

//validation
import * as yup from "yup"
import { yupResolver } from "@hookform/resolvers/yup"

//redux
import { useSelector, useDispatch } from "react-redux"
import { createSelector } from "reselect"
import Select from "react-select"

import Switch from "react-switch"
import { OnSymbol, Offsymbol } from "helpers/switch_helper"

import {
  Button,
  Card,
  CardBody,
  CardText,
  CardSubtitle,
  CardTitle,
  Col,
  Container,
  Form,
  Input,
  Label,
  Row,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Alert,
  FormFeedback,
  InputGroup,
} from "reactstrap"
import Flatpickr from "react-flatpickr"
import classnames from "classnames"
import {
  getObservationDetail as onGetObservationDetail,
  getRoutes as onGetRoutes,
  getKeyPoints as onGetKeyPoints,
  getStaffDetail as onGetStaffDetail,
  getPatientDetail as onGetPatientDetail,
} from "store/actions"
import { isEmpty, sample } from "lodash"
import EmptyContainer from "components/Common/EmptyContainer"
import { useDeepCompareEffect } from "hooks"
import Spinners from "components/Common/Spinner"
import { getSignatureImagePath } from "helpers/imageService"
import { QRCodeCanvas } from "qrcode.react"

//Date filter
import Moment from "moment"
import Observation from "./../../store/observation/reducer"

function ObservationReport() {
  const navigate = useNavigate()
  const routeParams = useParams()
  const dispatch = useDispatch()
  const [isLoading, setLoading] = useState(false)
  const [header, setHeader] = useState("")
  const [route, setRoute] = useState({})
  const { id } = routeParams

  /////////////////State/////////////////////////
  const { staff } = useSelector(state => state.staff)
  const { observation, loading } = useSelector(state => state.observation)
  const { patient } = useSelector(state => state.patient)
  const { routes, keypoints, localauthorities } = useSelector(
    state => state.helper
  )
  ////////////////////////////////////////////////////////

  useDeepCompareEffect(() => {
    if (id !== "new") {
      dispatch(onGetObservationDetail(id))
    }
    dispatch(onGetRoutes())
    setHeader("Observation Report")
  }, [dispatch])

  useEffect(() => {
    if (observation) {
      dispatch(onGetStaffDetail(observation.DoctorRowID))
      dispatch(onGetPatientDetail(observation.PatientRowID))
    }
  }, [observation])

  const onCancelClick = () => {
    navigate("/observations")
  }

  if (isLoading) {
    return <Spinners setLoading={setLoading} />
  }

  const divToPrint = useRef(null)
  const onPrintClick = () => {
    window.scrollTo({
      top: 0,
    })

    const printableArea = divToPrint.current

    if (printableArea) {
      window.print()
    }
  }

  useEffect(() => {
    const handleKeyDown = event => {
      // Check if Ctrl (or Cmd on Mac) + P is pressed
      if ((event.ctrlKey || event.metaKey) && event.key === "p") {
        // Scroll to the top of the page
        window.scrollTo({ top: 0 })
      }
    }

    // Add event listener when component mounts
    document.addEventListener("keydown", handleKeyDown)

    // Clean up event listener when component unmounts
    return () => {
      document.removeEventListener("keydown", handleKeyDown)
    }
  }, [])

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Observations" breadcrumbItem={header} />
          {routeParams.id !== "new" && isEmpty(observation) ? (
            <EmptyContainer
              backURL="/observations"
              message="There is no such observation!"
              linkText="Go to Observations Page"
            />
          ) : (
            <Row>
              <Col xs="12">
                <Card>
                  <CardBody>
                    <div className="d-flex align-items-center justify-content-between no-print mb-3">
                      <button
                        onClick={onPrintClick}
                        className="btn btn-success"
                      >
                        Print
                      </button>
                      <div className="">
                        <Button
                          type="submit"
                          color="secondary"
                          onClick={onCancelClick}
                          className=""
                        >
                          Cancel
                        </Button>
                      </div>
                    </div>
                    <div className="A4" ref={divToPrint}>
                      <table className="tbl">
                        <thead>
                          <tr>
                            <td>
                              <div className="page-header"></div>
                            </td>
                          </tr>
                        </thead>
                        <tbody>
                          <tr>
                            <td colSpan={3}>&nbsp;</td>
                          </tr>
                          <tr>
                            <td className="text-center" colSpan={3}>
                              <h5>
                                NHS healthcare professional letter template for
                                <br></br>
                                ECO4 and Great British Insulation Scheme Flex
                              </h5>
                            </td>
                          </tr>
                          <tr>
                            <td
                              style={{ border: "none", fontWeight: "bold" }}
                              dangerouslySetInnerHTML={{
                                __html:
                                  patient.Title +
                                  " " +
                                  patient.Name +
                                  "<br/> " +
                                  patient.Address +
                                  "<br/> " +
                                  patient.City +
                                  " " +
                                  patient.State +
                                  " " +
                                  patient.Country +
                                  "<br/> " +
                                  patient.PostCode,
                              }}
                            ></td>
                            <td
                              style={{ border: "none", fontWeight: "bold" }}
                              dangerouslySetInnerHTML={{
                                __html:
                                  staff.Title +
                                  " " +
                                  staff.Name +
                                  "<br/> " +
                                  staff.Address +
                                  "<br/> " +
                                  staff.City +
                                  " " +
                                  staff.State +
                                  " " +
                                  staff.Country +
                                  "<br/> " +
                                  staff.PostCode +
                                  "<br/>" +
                                  observation.ObservationDate,
                              }}
                            ></td>
                          </tr>
                          <tr>
                            <td colSpan={3}>
                              {"To " + observation.LocalAuthority + ","}
                            </td>
                          </tr>
                          <tr>
                            <td colSpan={3}>
                              Having carefully considered the patient’s medical
                              health conditions, I hereby refer
                              {patient.Name} for the ECO4 Flexibility (ECO4
                              Flex) policy and the Great British Insulation
                              Scheme Flex policy, as set out by the Department
                              for Energy Security and Net Zero via the following
                              route:
                            </td>
                          </tr>
                          <tr>
                            <td
                              colSpan={3}
                              dangerouslySetInnerHTML={{
                                __html: routes.filter(
                                  x => x.RouteID === observation.RouteID
                                )[0]?.Description,
                              }}
                            ></td>
                          </tr>
                          <tr>
                            <td colSpan={3}>
                              {observation.RouteKeyPoints &&
                                observation.RouteKeyPoints.filter(
                                  x =>
                                    x.RouteID === observation.RouteID &&
                                    x.Linked === true
                                ).map((item, index) => {
                                  return (
                                    <React.Fragment key={item.RouteKeyPointID}>
                                      <div>
                                        {index + 1 + " - " + item.RouteKeyPoint}
                                      </div>
                                    </React.Fragment>
                                  )
                                })}
                            </td>
                          </tr>
                          <tr>
                            <td
                              colSpan={3}
                              dangerouslySetInnerHTML={{
                                __html: routes.filter(
                                  x => x.RouteID === observation.RouteID
                                )[0]?.Declaration,
                              }}
                            ></td>
                          </tr>
                          <tr>
                            <td></td>
                            <td></td>
                          </tr>
                        </tbody>
                        <tfoot>
                          <tr className="page-footer">
                            {/* Column 1: Name and Phone */}
                            <td
                              style={{
                                paddingTop: "1mm",
                                verticalAlign: "top",
                                width: "33%", // Adjusted width for equal columns
                              }}
                            >
                              <p className="mb-2 p-0">
                                <strong>Name:</strong> - {staff.Name}
                              </p>
                              <p className="p-0 mb-0">
                                <strong>Phone:</strong> - {staff.PhoneNumber}
                              </p>
                            </td>

                            {/* Column 2: QR Code */}
                            <td
                              style={{
                                paddingTop: "1mm",
                                verticalAlign: "top",
                                width: "33%", // Adjusted width for equal columns
                                textAlign: "center", // Center align the QR code
                              }}
                            >
                              {
                                <QRCodeCanvas
                                  value={
                                    "NHS healthcare professional letter template for ECO4 and Great British Insulation Scheme Flex " +
                                    patient.Title +
                                    " " +
                                    patient.Name +
                                    ", " +
                                    patient.Address +
                                    ", " +
                                    patient.City +
                                    ", " +
                                    patient.State +
                                    ", " +
                                    patient.Country +
                                    ", " +
                                    patient.PostCode
                                  }
                                  size={64}
                                  style={{ padding: 0 }}
                                  className="footer-qr"
                                />
                              }
                            </td>

                            {/* Column 3: Email and Signature */}
                            <td
                              style={{
                                paddingTop: "1mm",
                                verticalAlign: "top",
                                width: "33%", // Adjusted width for equal columns
                              }}
                            >
                              <p className="p-0 mb-2">
                                <strong>Email:</strong> - {staff.Email}
                              </p>
                              <p
                                className="mb-2 p-0"
                                style={{ verticalAlign: "top" }}
                              >
                                <strong>Signature:</strong> -{" "}
                                {staff.Attachments && (
                                  <img
                                    src={getSignatureImagePath(
                                      staff?.Attachments[0]?.RowID,
                                      staff?.Attachments[0]?.FileName
                                    )}
                                    style={{
                                      maxHeight: 75,
                                      verticalAlign: "top",
                                    }}
                                    alt="Signature"
                                  />
                                )}
                              </p>
                            </td>
                          </tr>
                        </tfoot>
                      </table>
                    </div>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          )}
        </Container>
      </div>
    </React.Fragment>
  )
}

export default ObservationReport
