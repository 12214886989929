import {
  GET_OBSERVATIONS,
  GET_OBSERVATIONS_SUCCESS,
  GET_OBSERVATIONS_FAIL,
  GET_OBSERVATION_DETAIL,
  GET_OBSERVATION_DETAIL_SUCCESS,
  GET_OBSERVATION_DETAIL_FAIL,
  DELETE_OBSERVATION,
  DELETE_OBSERVATION_SUCCESS,
  DELETE_OBSERVATION_FAIL,
  ADD_NEW_OBSERVATION,
  ADD_OBSERVATION_SUCCESS,
  ADD_OBSERVATION_FAIL,
  UPDATE_OBSERVATION,
  UPDATE_OBSERVATION_SUCCESS,
  UPDATE_OBSERVATION_FAIL,
} from "./actionTypes"

export const getObservations = () => ({
  type: GET_OBSERVATIONS,
})

export const getObservationsSuccess = observations => ({
  type: GET_OBSERVATIONS_SUCCESS,
  payload: observations,
})

export const getObservationsFail = error => ({
  type: GET_OBSERVATIONS_FAIL,
  payload: error,
})

export const getObservationDetail = observationId => ({
  type: GET_OBSERVATION_DETAIL,
  observationId,
})

export const getObservationDetailSuccess = patient => ({
  type: GET_OBSERVATION_DETAIL_SUCCESS,
  payload: patient,
})

export const getObservationDetailFail = error => ({
  type: GET_OBSERVATION_DETAIL_FAIL,
  payload: error,
})

export const deleteObservation = observationId => ({
  type: DELETE_OBSERVATION,
  payload: observationId,
})

export const deleteObservationSuccess = patient => ({
  type: DELETE_OBSERVATION_SUCCESS,
  payload: patient,
})

export const deleteObservationFail = error => ({
  type: DELETE_OBSERVATION_FAIL,
  payload: error,
})

export const addNewObservation = patient => ({
  type: ADD_NEW_OBSERVATION,
  payload: patient,
})

export const addObservationSuccess = patient => ({
  type: ADD_OBSERVATION_SUCCESS,
  payload: patient,
})

export const addObservationFail = error => ({
  type: ADD_OBSERVATION_FAIL,
  payload: error,
})

export const updateObservation = patient => ({
  type: UPDATE_OBSERVATION,
  payload: patient,
})

export const updateObservationSuccess = patient => ({
  type: UPDATE_OBSERVATION_SUCCESS,
  payload: patient,
})

export const updateObservationFail = error => ({
  type: UPDATE_OBSERVATION_FAIL,
  payload: error,
})
