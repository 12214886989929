import {
  GET_PATIENTS,
  GET_PATIENTS_SUCCESS,
  GET_PATIENTS_FAIL,
  GET_PATIENT_DETAIL,
  GET_PATIENT_DETAIL_SUCCESS,
  GET_PATIENT_DETAIL_FAIL,
  DELETE_PATIENT,
  DELETE_PATIENT_SUCCESS,
  DELETE_PATIENT_FAIL,
  ADD_NEW_PATIENT,
  ADD_PATIENT_SUCCESS,
  ADD_PATIENT_FAIL,
  UPDATE_PATIENT,
  UPDATE_PATIENT_SUCCESS,
  UPDATE_PATIENT_FAIL,
} from "./actionTypes"

export const getPatients = () => ({
  type: GET_PATIENTS,
})

export const getPatientsSuccess = patients => ({
  type: GET_PATIENTS_SUCCESS,
  payload: patients,
})

export const getPatientsFail = error => ({
  type: GET_PATIENTS_FAIL,
  payload: error,
})

export const getPatientDetail = patientId => ({
  type: GET_PATIENT_DETAIL,
  patientId,
})

export const getPatientDetailSuccess = patient => ({
  type: GET_PATIENT_DETAIL_SUCCESS,
  payload: patient,
})

export const getPatientDetailFail = error => ({
  type: GET_PATIENT_DETAIL_FAIL,
  payload: error,
})

export const deletePatient = patientId => ({
  type: DELETE_PATIENT,
  payload: patientId,
})

export const deletePatientSuccess = patient => ({
  type: DELETE_PATIENT_SUCCESS,
  payload: patient,
})

export const deletePatientFail = error => ({
  type: DELETE_PATIENT_FAIL,
  payload: error,
})

export const addNewPatient = patient => ({
  type: ADD_NEW_PATIENT,
  payload: patient,
})

export const addPatientSuccess = patient => ({
  type: ADD_PATIENT_SUCCESS,
  payload: patient,
})

export const addPatientFail = error => ({
  type: ADD_PATIENT_FAIL,
  payload: error,
})

export const updatePatient = patient => ({
  type: UPDATE_PATIENT,
  payload: patient,
})

export const updatePatientSuccess = patient => ({
  type: UPDATE_PATIENT_SUCCESS,
  payload: patient,
})

export const updatePatientFail = error => ({
  type: UPDATE_PATIENT_FAIL,
  payload: error,
})
