import {
  GET_COUNTRIES,
  GET_COUNTRIES_SUCCESS,
  GET_COUNTRIES_FAIL,
  GET_STATES,
  GET_STATES_SUCCESS,
  GET_STATES_FAIL,
  GET_CITIES,
  GET_CITIES_SUCCESS,
  GET_CITIES_FAIL,
  GET_ROUTES,
  GET_ROUTES_SUCCESS,
  GET_ROUTES_FAIL,
  GET_KEY_POINTS,
  GET_KEY_POINTS_SUCCESS,
  GET_KEY_POINTS_FAIL,
  GET_LOCAL_AUTHORITIES,
  GET_LOCAL_AUTHORITIES_SUCCESS,
  GET_LOCAL_AUTHORITIES_FAIL,
} from "./actionTypes"

export const getCountries = () => ({
  type: GET_COUNTRIES,
})

export const getCountriesSuccess = countries => ({
  type: GET_COUNTRIES_SUCCESS,
  payload: countries,
})

export const getCountriesFail = error => ({
  type: GET_COUNTRIES_FAIL,
  payload: error,
})

export const getStates = countryID => ({
  type: GET_STATES,
  payload: countryID,
})

export const getStatesSuccess = states => ({
  type: GET_STATES_SUCCESS,
  payload: states,
})

export const getStatesFail = error => ({
  type: GET_STATES_FAIL,
  payload: error,
})

export const getCities = stateID => ({
  type: GET_CITIES,
  payload: stateID,
})

export const getCitiesSuccess = cities => ({
  type: GET_CITIES_SUCCESS,
  payload: cities,
})

export const getCitiesFail = error => ({
  type: GET_CITIES_FAIL,
  payload: error,
})

export const getRoutes = () => ({
  type: GET_ROUTES,
})

export const getRoutesSuccess = routes => ({
  type: GET_ROUTES_SUCCESS,
  payload: routes,
})

export const getRoutesFail = error => ({
  type: GET_ROUTES_FAIL,
  payload: error,
})

export const getKeyPoints = () => ({
  type: GET_KEY_POINTS,
})

export const getKeyPointsSuccess = keypoints => ({
  type: GET_KEY_POINTS_SUCCESS,
  payload: keypoints,
})

export const getKeyPointsFail = error => ({
  type: GET_KEY_POINTS_FAIL,
  payload: error,
})

export const getLocalAuthorities = () => ({
  type: GET_LOCAL_AUTHORITIES,
})

export const getLocalAuthoritiesSuccess = localauthorities => ({
  type: GET_LOCAL_AUTHORITIES_SUCCESS,
  payload: localauthorities,
})

export const getLocalAuthoritiesFail = error => ({
  type: GET_LOCAL_AUTHORITIES_FAIL,
  payload: error,
})
