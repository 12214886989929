import PropTypes from "prop-types"
import React from "react"
import { useSelector } from "react-redux"
import { createSelector } from "reselect"
import { Routes, Route } from "react-router-dom"
import { layoutTypes } from "./constants/layout"
// Import Routes all
import { authProtectedRoutes, publicRoutes } from "./routes"

// Import all middleware
import Authmiddleware from "./routes/route"

// layouts Format
import HorizontalLayout from "./components/HorizontalLayout/"
import VerticalLayout from "./components/VerticalLayout/"
import NonAuthLayout from "./components/NonAuthLayout"

// Import scss
import "./assets/scss/theme.scss"
// Import custom css
import "./assets/css/custom.css"

const App = () => {
  const selectLayoutState = state => state.Layout
  const LayoutProperties = createSelector(selectLayoutState, layout => ({
    layoutType: layout.layoutType,
  }))

  const { layoutType } = useSelector(LayoutProperties)

  const Layout = VerticalLayout

  return (
    <React.Fragment>
      <Routes>
        {publicRoutes.map((route, idx) => (
          <Route
            path={route.path}
            element={<NonAuthLayout>{route.component}</NonAuthLayout>}
            key={idx}
            exact={true}
          />
        ))}

        {authProtectedRoutes.map((route, idx) => (
          <Route
            path={route.path}
            element={
              <Authmiddleware>
                <Layout>{route.component}</Layout>
              </Authmiddleware>
            }
            key={idx}
            exact={true}
          />
        ))}
      </Routes>
    </React.Fragment>
  )
}

App.propTypes = {
  layout: PropTypes.any,
}

export default App
