/*Get COUNTRIES */
export const GET_COUNTRIES = "GET_COUNTRIES"
export const GET_COUNTRIES_SUCCESS = "GET_COUNTRIES_SUCCESS"
export const GET_COUNTRIES_FAIL = "GET_COUNTRIES_FAIL"

/*Get STATES */
export const GET_STATES = "GET_STATES"
export const GET_STATES_SUCCESS = "GET_STATES_SUCCESS"
export const GET_STATES_FAIL = "GET_STATES_FAIL"

/*Get CITIES */
export const GET_CITIES = "GET_CITIES"
export const GET_CITIES_SUCCESS = "GET_CITIES_SUCCESS"
export const GET_CITIES_FAIL = "GET_CITIES_FAIL"

/*Get ROUTES */
export const GET_ROUTES = "GET_ROUTES"
export const GET_ROUTES_SUCCESS = "GET_ROUTES_SUCCESS"
export const GET_ROUTES_FAIL = "GET_ROUTES_FAIL"

/*Get KEY POINTS */
export const GET_KEY_POINTS = "GET_KEY_POINTS"
export const GET_KEY_POINTS_SUCCESS = "GET_KEY_POINTS_SUCCESS"
export const GET_KEY_POINTS_FAIL = "GET_KEY_POINTS_FAIL"

/*Get LOCAL AUTHORITIES */
export const GET_LOCAL_AUTHORITIES = "GET_LOCAL_AUTHORITIES"
export const GET_LOCAL_AUTHORITIES_SUCCESS = "GET_LOCAL_AUTHORITIES_SUCCESS"
export const GET_LOCAL_AUTHORITIES_FAIL = "GET_LOCAL_AUTHORITIES_FAIL"
