import React, { useState, useEffect } from "react"
import { Link, useNavigate, useParams } from "react-router-dom"

//import components
import Breadcrumbs from "../../components/Common/Breadcrumb"

//react form
import {
  FormProvider,
  useForm,
  Controller,
  useFieldArray,
} from "react-hook-form"

//validation
import * as yup from "yup"
import { yupResolver } from "@hookform/resolvers/yup"

//redux
import { useSelector, useDispatch } from "react-redux"
import { createSelector } from "reselect"
import Select from "react-select"

import Switch from "react-switch"
import { OnSymbol, Offsymbol } from "helpers/switch_helper"

import {
  Button,
  Card,
  CardBody,
  CardText,
  CardSubtitle,
  CardTitle,
  Col,
  Container,
  Form,
  Input,
  Label,
  Row,
  Nav,
  NavItem,
  NavLink,
  TabContent,
  TabPane,
  Alert,
  FormFeedback,
  InputGroup,
} from "reactstrap"
import Flatpickr from "react-flatpickr"
import classnames from "classnames"
import {
  addNewObservation as onAddNewObservation,
  updateObservation as onUpdateObservation,
  getObservationDetail as onGetObservationDetail,
  getStaffs as onGetStaffs,
  getPatients as onGetPatients,
  getLocalAuthorities as onGetLocalAuthorities,
  getRoutes as onGetRoutes,
  getKeyPoints as onGetKeyPoints,
} from "store/actions"
import { isEmpty, sample } from "lodash"
import EmptyContainer from "components/Common/EmptyContainer"
import { useDeepCompareEffect, useUserDetail } from "hooks"
import Spinners from "components/Common/Spinner"

//Date filter
import Moment from "moment"

function ObservationAddEdit() {
  const navigate = useNavigate()
  const routeParams = useParams()
  const dispatch = useDispatch()
  const [isLoading, setLoading] = useState(false)
  const [header, setHeader] = useState("")
  const [keyPoints, setKeyPoints] = useState([])
  const [route, setRoute] = useState({})
  const { userprofile } = useUserDetail()
  const { id } = routeParams
  /**
   * Form Validation Schema
   */
  const schema = yup.object().shape({
    ObservationDate: yup.string().required("Required"),
    Doctors: yup.object().required("Required"),
    Patients: yup.object().required("Required"),
    LocalAuthorities: yup.object().required("Required"),
    Routes: yup.object().required("Required"),
    RouteKeyPoints: yup.array().of(
      yup.object().shape({
        RouteKeyPointID: yup.number(),
        Linked: yup.bool(),
        RouteKeyPoint: yup.string(),
      })
    ),
  })

  const formdefault = {
    ObservationDate: Moment().format("DD MMM, YYYY"),
  }

  const methods = useForm({
    mode: "onChange",
    defaultValues: formdefault,
    resolver: yupResolver(schema),
  })
  const { reset, control, formState, setValue, getValues, trigger } = methods
  const { errors, isValid } = formState

  const { fields, append, remove } = useFieldArray({
    name: "RouteKeyPoints",
    control,
  })

  /////////////////State/////////////////////////
  const { staffs } = useSelector(state => state.staff)
  const { observation, loading } = useSelector(state => state.observation)
  const { patients } = useSelector(state => state.patient)
  const { routes, keypoints, localauthorities } = useSelector(
    state => state.helper
  )
  ////////////////////////////////////////////////////////

  useDeepCompareEffect(() => {
    if (id !== "new") {
      dispatch(onGetObservationDetail(id))
    }
    dispatch(onGetStaffs())
    dispatch(onGetPatients())
    dispatch(onGetKeyPoints())
    dispatch(onGetLocalAuthorities())
    dispatch(onGetRoutes())
  }, [dispatch])

  useEffect(() => {
    if (id === "new") {
      setHeader("Add Observation")
      reset(formdefault)
    } else {
      setHeader("Edit Observation")
      if (!isEmpty(observation)) {
        reset(observation)
      }
    }
  }, [id, observation])

  useEffect(() => {
    if (routes) {
      let route = routes.filter(x => x.RouteID === observation.RouteID)
      setRoute({ ...route[0] })
    }

    if (!isEmpty(observation)) {
      let points = observation.RouteKeyPoints.filter(
        x => x.RouteID === observation.RouteID
      )
      setValue("RouteKeyPoints", points)
    }
  }, [routes, observation])

  const handleSave = () => {
    trigger() //for form validation
    if (isValid) {
      const { id } = routeParams
      if (id === "new") {
        dispatch(onAddNewObservation(getValues()))
      } else {
        dispatch(onUpdateObservation(getValues()))
      }
      navigate("/observations")
    }
  }

  const handleRoute = e => {
    setValue("Routes", e)
    let points
    if (!isEmpty(observation)) {
      points = observation.RouteKeyPoints.filter(x => x.RouteID === e.RouteID)
    } else {
      points = keypoints.filter(x => x.RouteID === e.RouteID)
    }
    setValue("RouteKeyPoints", points)
    setRoute(e)
  }

  const onCancelClick = () => {
    navigate("/observations")
  }

  if (isLoading) {
    return <Spinners setLoading={setLoading} />
  }

  return (
    <React.Fragment>
      <div className="page-content">
        <Container fluid>
          <Breadcrumbs title="Observations" breadcrumbItem={header} />
          {routeParams.id !== "new" && isEmpty(observation) ? (
            <EmptyContainer
              backURL="/observations"
              message="There is no such observation!"
              linkText="Go to Observations Page"
            />
          ) : (
            <Row>
              <Col xs="12">
                <Card>
                  <CardBody>
                    <CardTitle>Basic Information</CardTitle>
                    <p className="card-title-desc mb-4">
                      Fill all information below
                    </p>
                    <FormProvider {...methods}>
                      <div className="row">
                        <div className="col-lg-6">
                          <div className="mb-3">
                            <Label>Observation Date</Label>
                            <Controller
                              name="ObservationDate"
                              control={control}
                              render={({ field }) => (
                                <>
                                  <InputGroup>
                                    <Flatpickr
                                      {...field}
                                      className="form-control d-block"
                                      id="ObservationDate"
                                      options={{
                                        dateFormat: "d M, Y",
                                      }}
                                      onChange={(
                                        selectedDates,
                                        dateStr,
                                        instance
                                      ) => {
                                        setValue("ObservationDate", dateStr)
                                      }}
                                    />
                                    {/* <div className="input-group-text">
                                      <i
                                        className="mdi mdi-trash-can-outline"
                                        aria-hidden="true"
                                        onClick={() =>
                                          setValue("ObservationDate", "")
                                        }
                                      />
                                    </div> */}
                                  </InputGroup>
                                  {errors?.ObservationDate?.message ? (
                                    <FormFeedback
                                      type="invalid"
                                      className="d-block"
                                    >
                                      {errors?.ObservationDate?.message}
                                    </FormFeedback>
                                  ) : null}
                                </>
                              )}
                            />
                          </div>
                          <div className="mb-3">
                            <Label>Doctor</Label>
                            <Controller
                              name="Doctors"
                              control={control}
                              render={({ field }) => (
                                <>
                                  <Select
                                    {...field}
                                    id="Doctors"
                                    options={
                                      userprofile && userprofile.RoleID === 8
                                        ? staffs.filter(
                                            x => x.UserID === userprofile.UserID
                                          )
                                        : staffs.filter(x => x.RoleID === 8)
                                    }
                                    getOptionLabel={option => option.Name}
                                    getOptionValue={option => option.UserID}
                                    required
                                    aria-invalid={!!errors.Doctors}
                                    classNamePrefix="select2-selection"
                                  />
                                  {errors?.Doctors?.message ? (
                                    <FormFeedback
                                      type="invalid"
                                      className="d-block"
                                    >
                                      {errors?.Doctors?.message}
                                    </FormFeedback>
                                  ) : null}
                                </>
                              )}
                            />
                          </div>
                          <div className="mb-3">
                            <Label>Patient</Label>
                            <Controller
                              name="Patients"
                              control={control}
                              render={({ field }) => (
                                <>
                                  <Select
                                    {...field}
                                    id="Patients"
                                    options={patients}
                                    getOptionLabel={option => option.Name}
                                    getOptionValue={option => option.PatientID}
                                    required
                                    aria-invalid={!!errors.Patients}
                                    classNamePrefix="select2-selection"
                                  />
                                  {errors?.Patients?.message ? (
                                    <FormFeedback
                                      type="invalid"
                                      className="d-block"
                                    >
                                      {errors?.Patients?.message}
                                    </FormFeedback>
                                  ) : null}
                                </>
                              )}
                            />
                          </div>
                          <div className="mb-3">
                            <Label>Local Authority</Label>
                            <Controller
                              name="LocalAuthorities"
                              control={control}
                              render={({ field }) => (
                                <>
                                  <Select
                                    {...field}
                                    id="LocalAuthorities"
                                    options={localauthorities}
                                    getOptionLabel={option =>
                                      option.LocalAuthorityName
                                    }
                                    getOptionValue={option =>
                                      option.LocalAuthorityID
                                    }
                                    required
                                    aria-invalid={!!errors.LocalAuthorities}
                                    classNamePrefix="select2-selection"
                                  />
                                  {errors?.LocalAuthorities?.message ? (
                                    <FormFeedback
                                      type="invalid"
                                      className="d-block"
                                    >
                                      {errors?.LocalAuthorities?.message}
                                    </FormFeedback>
                                  ) : null}
                                </>
                              )}
                            />
                          </div>
                          <div className="mb-3">
                            <Label>Route</Label>
                            <Controller
                              name="Routes"
                              control={control}
                              render={({ field }) => (
                                <>
                                  <Select
                                    {...field}
                                    id="Routes"
                                    options={routes}
                                    getOptionLabel={option => option.RouteName}
                                    getOptionValue={option => option.RouteID}
                                    required
                                    aria-invalid={!!errors.Routes}
                                    onChange={e => handleRoute(e)}
                                    classNamePrefix="select2-selection"
                                  />
                                  {errors?.Routes?.message ? (
                                    <FormFeedback
                                      type="invalid"
                                      className="d-block"
                                    >
                                      {errors?.Routes?.message}
                                    </FormFeedback>
                                  ) : null}
                                </>
                              )}
                            />
                          </div>
                          {route && (
                            <div
                              className="mb-3"
                              dangerouslySetInnerHTML={{
                                __html: route?.Description,
                              }}
                            ></div>
                          )}

                          <div className="mb-3">
                            {fields &&
                              fields
                                .filter(x => x.RouteID === route.RouteID)
                                .map((item, index) => {
                                  return (
                                    <React.Fragment key={item.RouteKeyPointID}>
                                      <Row>
                                        <Col sm={1}>
                                          <Controller
                                            control={control}
                                            name={`RouteKeyPoints[${index}].Linked`}
                                            render={({ field }) => (
                                              <>
                                                <input
                                                  {...field}
                                                  defaultChecked={field.value}
                                                  id={`RouteKeyPoints[${index}].Linked`}
                                                  type="checkbox"
                                                  className="form-check-input"
                                                />
                                              </>
                                            )}
                                          />
                                        </Col>
                                        <Col sm={11}>{item.RouteKeyPoint}</Col>
                                      </Row>
                                    </React.Fragment>
                                  )
                                })}
                          </div>
                        </div>
                      </div>
                      <div className="d-flex flex-wrap gap-2">
                        <Button
                          color="success"
                          className="btn"
                          onClick={handleSave}
                        >
                          {routeParams.id === "new" ? "Save" : "Update"}
                        </Button>
                        <Button
                          type="submit"
                          color="secondary"
                          onClick={onCancelClick}
                          className=""
                        >
                          Cancel
                        </Button>
                      </div>
                    </FormProvider>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          )}
        </Container>
      </div>
    </React.Fragment>
  )
}

export default ObservationAddEdit
