/*Get PATIENT */
export const GET_PATIENTS = "GET_PATIENTS"
export const GET_PATIENTS_SUCCESS = "GET_PATIENTS_SUCCESS"
export const GET_PATIENTS_FAIL = "GET_PATIENTS_FAIL"

/**
 * Get PATIENT DETAILS
 */
export const GET_PATIENT_DETAIL = "GET_PATIENT_DETAIL"
export const GET_PATIENT_DETAIL_SUCCESS = "GET_PATIENT_DETAIL_SUCCESS"
export const GET_PATIENT_DETAIL_FAIL = "GET_PATIENT_DETAIL_FAIL"

/**
 * Delete PATIENT
 */
export const DELETE_PATIENT = "DELETE_PATIENT"
export const DELETE_PATIENT_SUCCESS = "DELETE_PATIENT_SUCCESS"
export const DELETE_PATIENT_FAIL = "DELETE_PATIENT_FAIL"

/**
 * Add PATIENT
 */
export const ADD_NEW_PATIENT = "ADD_NEW_PATIENT"
export const ADD_PATIENT_SUCCESS = "ADD_PATIENT_SUCCESS"
export const ADD_PATIENT_FAIL = "ADD_PATIENT_FAIL"

/**
 * Update PATIENT
 */
export const UPDATE_PATIENT = "UPDATE_PATIENT"
export const UPDATE_PATIENT_SUCCESS = "UPDATE_PATIENT_SUCCESS"
export const UPDATE_PATIENT_FAIL = "UPDATE_PATIENT_FAIL"
