//REGISTER
export const POST_FAKE_REGISTER = "/post-fake-register"

//LOGIN
export const POST_LOGIN = "/auth"
export const POST_FAKE_LOGIN = "/post-fake-login"
export const POST_FAKE_JWT_LOGIN = "/post-jwt-login"
export const POST_FAKE_PASSWORD_FORGET = "/fake-forget-pwd"
export const POST_FAKE_JWT_PASSWORD_FORGET = "/jwt-forget-pwd"
export const SOCIAL_LOGIN = "/social-login"

//PROFILE
export const POST_EDIT_JWT_PROFILE = "/post-jwt-profile"
export const POST_EDIT_PROFILE = "/post-fake-profile"

//dashboard data

//Adults
export const ADULT_URL = "/adults"
export const ADD_NEW_ADULT = "/adults/saveadult"

//Children
export const CHILD_URL = "/children"
export const ADD_NEW_CHILD = "/children/savechild"
export const CHILD_PROVIDED_FOOD_URL = "/children/providedfood"
export const CHILD_NOTES_URL = "/children/notes"
export const CHILD_AVAILABILITY_URL = "/children/availabilities"
export const CHILD_RELATIONS_URL = "/children/relations"

//Child Schedules
export const ADD_CHILD_SCHEDULES_URL = "/childscheduling/assign"
export const CHILD_SCHEDULES_URL = "/childscheduling"

//Classes
export const CLASS_URL = "/classes"
export const ADD_NEW_CLASS = "/classes/saveclass"
export const ADD_CLASS_NOTES = "/classes/notes"

//Class Happenings
export const CLASS_HAPPENING_URL = "/classhappenings"
export const CLASS_HAPPENING_TYPE_URL = "/classhappenings/happeningtypes"
export const ADD_CLASS_HAPPENING_URL = "/classhappenings/add"

//Class Schedules
export const ADD_CLASS_SCHEDULES_URL = "/classscheduling/assign"
export const CLASS_SCHEDULES_URL = "/classscheduling"

//ClassTypes
export const CLASS_TYPE_URL = "/classes/classtypes"

//Documents
export const DOCUMENT_URL = "/document"

//Meal
export const MEAL_URL = "/meals"
export const ADD_NEW_MEAL = "/meals/savemeal"
//Meal Schedules
export const ADD_MEAL_SCHEDULES_URL = "/mealscheduling/assign"
export const MEAL_SCHEDULES_URL = "/mealscheduling"

//Bus
export const BUS_URL = "/buses"
export const ADD_NEW_BUS = "/buses/savebus"
//Bus Schedules
export const ADD_BUS_SCHEDULES_URL = "/busscheduling/assign"
export const BUS_SCHEDULES_URL = "/busscheduling"

//Room
export const ROOM_URL = "/rooms"
export const ADD_NEW_ROOM = "/rooms/saveroom"

//Program
export const PROGRAM_URL = "/pricing/programs"
export const ADD_NEW_PROGRAM = "pricing/programs/save"

//Flexible
export const FLEXIBLE_URL = "/pricing/flexibles"
export const ADD_NEW_FLEXIBLE = "pricing/flexibles/save"
export const UPDATE_FLEXIBLE_SWITCH = "pricing/flexibles/switch"
export const UPDATE_FLEXIBLE_DEFAULT_RATE = "pricing/flexibles/defaultrate"

//AddOns
export const ADDON_URL = "/pricing/addons"
export const ADD_ADDON = "pricing/addons/save"

//Charges
export const CHARGE_URL = "/pricing/charges"
export const ADD_CHARGE = "pricing/charges/save"
export const CHARGETYPE_URL = "pricing/chargetypes"

//Navigation
export const NAVIGATION_URL = "/navigation"

//Enrollment
export const ENROLLMENT_URL = "/pricing/enrollments"
export const ADD_ENROLLMENT = "pricing/enrollments/save"

//Staff
export const STAFF_URL = "/users"
export const ADD_NEW_STAFF = "/users/saveuser"
export const UPDATE_STAFF = "/users/updateuser"

//Users
export const USER_URL = "/users"

//ROLES
export const ROLE_URL = "/roles"
//Staff Schedules
export const ADD_STAFF_SCHEDULES_URL = "/staffscheduling/assign"
export const STAFF_SCHEDULES_URL = "/staffscheduling"

//Photo
export const PHOTO_URL = "/upload"

//Payrate
export const PAYRATE_URL = "/payrates"
export const ADD_NEW_PAYRATE = "/payrates/savepayrate"
export const PAYRATE_DETAIL_URL = "/getpayrate"

//employeementtypes
export const EMPLOYEEMENT_TYPE_URL = "/payrates/employmenttypes"

//ClassificationTypes
export const CLASSIFICATION_TYPE_URL = "/payrates/classificationtypes"

/*Common helper functions */
export const COUNTRY_URL = "/helper/countries"
export const STATE_URL = "/helper/states"
export const CITY_URL = "/helper/cities"
export const GET_SCHEDULE_TYPES_URL = "/helper/scheduletypes"
export const GET_ABSENT_TYPES_URL = "/helper/absenttypes"
export const GET_RELATION_TYPES_URL = "/helper/relationtypes"
export const GET_PERMISSION_TYPES_URL = "/helper/permissiontypes"
export const GET_NOTIFICATION_EVENTS_URL = "/helper/notificationevents"
export const GET_NOTIFICATION_RECIPIENTS_URL = "/helper/notificationrecipients"
export const GET_FORUM_VISIBILITY_TYPES = "/helper/forumvisibilitytypes"
export const GET_SOURCES = "/helper/sources"
export const GET_FUELS = "/helper/fuels"
export const GET_CONSUMPTIONS = "/helper/consumptions"
export const GET_PROTOCOLS = "/helper/protocols"
export const GET_UNITS = "/helper/units"
export const GET_STANDARDLIMITS = "/helper/standardlimits"
export const GET_APCS = "/helper/apcs"
export const GET_DGTYPES = "/helper/dgtypes"
export const GET_DGSTANDARDLIMITS = "/helper/dgstandardlimits"
export const GET_ROUTES = "/helper/routes"
export const GET_KEYPOINTS = "/helper/keypoints"
export const GET_LOCALAUTHORITIES = "/helper/localauthorities"

//Staff Availability
export const STAFF_AVAILABILITY_URL = "/availabilities"

//Staff Profile
export const STAFF_PROFILE_URL = "/users/profile"

//Certificate
export const CERTIFICATE_URL = "/certifications"
export const CERTIFICATE_SAVE_URL = "/certifications/save"
export const CERTIFICATE_UPDATE_URL = "/certifications/update"
export const GET_CERTIFICATE_DAYS_URL = "/certifications/requireddays"
export const STAFF_CERTIFICATION_URL = "/certifications/staffcertification"

//Immunization
export const IMMUNIZATION_URL = "/immunizations"
export const IMMUNIZATION_SAVE_URL = "/immunizations/save"
export const IMMUNIZATION_UPDATE_URL = "/immunizations/update"
export const IMMUNIZATION_CHILD_URL = "/immunizations/childimmunization"

//Staff Happening
export const HAPPENING_TYPE_URL = "/staffhappenings/happeningtypes"
export const GET_STAFF_HAPPENING_URL = "/staffhappenings"
export const ADD_STAFF_HAPPENING = "/staffhappenings/addstaffhappening"
export const STAFF_HAPPENING_URL = "/staffhappenings"
export const HOUR_TYPE_URL = "/staffhappenings/hourtypes"

//Notifications
export const NOTIFICATION_URL = "/notifications"
export const ADD_NEW_NOTIFICATION = "/notifications/savenotification"

//Forums
export const FORUM_URL = "/forums"
export const ADD_NEW_FORUM = "/forums/saveforum"
export const ADD_NEW_FORUM_POST = "/forums/saveforumpost"
export const DELETE_FORUM_POST = "/forums/deleteforumpost"

//Chat Message
export const CHAT_URL = "/chats"
export const ADD_NEW_CHAT = "/chats/savechat"
export const ADD_NEW_CHAT_MESSAGE = "/chats/savechatmessage"
export const DELETE_CHAT_MESSAGE = "/chats/deletechatmessage"
export const LATEST_CHAT_URL = "/chats/latest"

export const GET_GENDER_URL = "/helper/genders"

export const GET_TITLE_URL = "/helper/titles"

//Child Happening
//export const HAPPENING_TYPE_URL = "/childhappenings/happeningtypes"
export const GET_CHILD_HAPPENING_URL = "/childhappenings"
export const ADD_CHILD_HAPPENING = "/childhappenings/addchildhappening"
export const CHILD_HAPPENING_URL = "/childhappenings"
//export const HOUR_TYPE_URL = "/staffhappenings/hourtypes"

//Center Profile
export const CENTER_PROFILE_URL = "/centerprofile"
export const CENTER_PROFILE_UPDATE_URL = "/centerprofile/center"
export const CENTER_PROFILE_ADDRESS_UPDATE_URL = "/centerprofile/address"
export const CENTER_PROFILE_CONTACT_UPDATE_URL = "/centerprofile/contact"
export const CENTER_PROFILE_CLOSING_UPDATE_URL = "/centerprofile/closing"
export const CENTER_PROFILE_OPEATING_HOUR_UPDATE_URL =
  "/centerprofile/availability"

//Bus
export const ALBUM_URL = "/albums"
export const ADD_NEW_ALBUM_URL = "/albums/savealbum"
export const ADD_NEW_ALBUMMEDIA_URL = "/albums/savealbummedia"
export const DELETE_ALBUMMEDIA_URL = "/albums/deletealbummedia"

//Lessons
export const LESSON_URL = "/lessons"
export const ADD_NEW_LESSON = "/lessons/savelesson"

//Curriculums
export const CURRICULUM_URL = "/curriculums"
export const ADD_NEW_CURRICULUM = "/curriculums/savecurriculum"

//Notes
export const NOTE_URL = "/notes"
export const ADD_NEW_NOTE = "/notes/savenote"

//Reports
export const REPORT_URL = "/reports"
export const REPORT_TYPE_URL = "/reports/reporttypes"

//Collections
export const COLLECTION_URL = "/collection"
export const ADD_NEW_COLLECTION = "/collection/save"
export const SAMPLE_TYPES_URL = "/collection/sampletypes"
export const SAMPLE_STATUSES_URL = "/collection/samplestatuses"
export const PARAMTER_TYPES_URL = "/collection/parametertypes"
export const PARAMTERS_URL = "/collection/parameters"
export const TEST_REPORT_URL = "/collection/report"
export const TEST_REPORT_SAVE_URL = "/collection/report/save"
export const BOOKING_URL = "/booking"
export const ADD_NEW_BOOKING = "/booking/save"
export const SECTION_URL = "/section"
export const ADD_NEW_SECTION = "/section/save"
export const SECTION_TYPES_URL = "/section/sectiontypes"
export const DISPATCH_URL = "/dispatch"
export const ADD_NEW_DISPATCH = "/dispatch/save"
export const PATIENT_URL = "/patient"
export const ADD_NEW_PATIENT = "/patient/save"
export const COLLECTION_SAMPLE_URL = "/collection/sample"
export const COLLECTION_PARAMETER_URL = "/collection/collectionparameters"
export const SECTION_PARAMETER_URL = "/section/sectionparameters"
export const TEST_REPORT_INVOICE_URL = "/collection/report/invoice"
export const COLLECTION_DG_VISIBILITY_URL = "/collection/dgvisibility"
export const OBSERVATION_URL = "/observation"
export const ADD_NEW_OBSERVATION = "/observation/save"
