import React, { useState, useEffect } from "react"

//controls
import {
  Button,
  Col,
  Form,
  Input,
  Label,
  Row,
  FormFeedback,
  UncontrolledTooltip,
} from "reactstrap"
import Switch from "react-switch"

//react form
import { FormProvider, useForm, Controller } from "react-hook-form"
import Select from "react-select"
import { createSelector } from "reselect"

//navigation
import { Link, useNavigate, useParams } from "react-router-dom"

//validation
import * as yup from "yup"
import { yupResolver } from "@hookform/resolvers/yup"

//redux
import { useSelector, useDispatch } from "react-redux"

//custom hooks
import { useDeepCompareEffect } from "hooks"

import {
  addNewStaff as onAddNewStaff,
  getRoles as onGetRoles,
  updateStaff as onUpdateStaff,
  resetStaff as onResetStaff,
  getTitles as onGetTitles,
} from "store/actions"
import { OnSymbol, Offsymbol } from "helpers/switch_helper"
import GenderField from "components/Common/Gender/Gender"
import Spinners from "components/Common/Spinner"
import {
  isValidFileType,
  MAX_FILE_SIZE,
  ValidExtensions,
} from "pages/Utility/constants"
import FileUpload from "components/Common/FileUpload"
import ProfilePicture from "components/Common/ProfilePicture"
import { EnumObjectTypes } from "helpers/enum_helper"
import { getSignatureImagePath } from "helpers/imageService"
import LocationField from "components/Common/Location/Location"

function BasicInfoTab({ staff, loading }) {
  const navigate = useNavigate()
  const routeParams = useParams()
  const dispatch = useDispatch()
  const [isLoading, setLoading] = useState(loading)

  /**
   * Form Validation Schema
   */
  const schema = yup.object().shape({
    Titles: yup.object().required("Required"),
    FirstName: yup
      .string()
      .required("Required")
      .min(3, "First name must be at least 3 characters"),
    LastName: yup.string().required("Required"),
    LoginUserName: yup.string().required("Required"),
    LoginPassword: yup.string().required("Required"),
    IsActive: yup.bool(),
    Email: yup.string().required("Required").email("Must be a valid Email"),
    PhoneNumber: yup
      .string()
      .min(0, "Should be between 0 and 10")
      .max(10, "Should be between 0 and 10"),
    Roles: yup.object().required("Required"),
    Genders: yup.object().required("Required"),
    Address: yup.string().required("Required"),
    Countries: yup.object(),
    States: yup.object(),
    Cities: yup.object().required("Required"),
    PostCode: yup.string().max(10, "Zipcode should be max 10 characters"),
    Signature: yup.array().of(
      yup
        .mixed()
        .test("is-valid-type", "Not a valid file type", value => {
          if (!value) return true // Allow empty values
          return isValidFileType(value && value.name.toLowerCase(), "image")
        })
        .test("is-valid-size", "Max allowed size is 5MB", value => {
          if (!value) return true // Allow empty values
          return value.size <= MAX_FILE_SIZE // Convert KB to bytes
        })
    ),
  })

  const methods = useForm({
    mode: "onChange",
    defaultValues: {
      LoginUserName: "",
      LoginPassword: "",
      FirstName: "",
      LastName: "",
      Email: "",
      PhoneNumber: "",
      Address: "",
      IsActive: true,
    },
    resolver: yupResolver(schema),
  })
  const { reset, control, formState, watch, setValue, getValues, trigger } =
    methods
  const { errors, isValid } = formState

  const role = watch("Roles")

  useDeepCompareEffect(() => {
    function updateStaffState() {
      dispatch(onGetRoles())
      dispatch(onGetTitles())
      //reset form with server data
      reset(staff)
    }

    updateStaffState()
  }, [dispatch])

  //////////////////ROLE STATE/////////////////////
  const { roles } = useSelector(state => state.role)
  const { titles } = useSelector(state => state.staff)
  ///////////////////////////////////////

  useEffect(() => {
    return () => {
      dispatch(onResetStaff())
    }
  }, [dispatch])

  const onCancelClick = () => {
    navigate("/users")
  }

  const handleSave = () => {
    trigger() //for form validation
    if (isValid) {
      const { id } = routeParams

      if (id === "new") {
        dispatch(onAddNewStaff(getValues()))
      } else {
        dispatch(onUpdateStaff(getValues()))
      }
      navigate("/users")
    }
  }

  const removeFile = attachmentID => {
    const files = getValues("Attachments")
    const index = files.findIndex(item => item.AttachmentID === attachmentID)

    if (index !== -1) {
      const updatedData = [...files]
      updatedData[index] = { ...updatedData[index], Active: false }

      setValue("Attachments", updatedData)
    }
  }

  if (isLoading) {
    return <Spinners setLoading={setLoading} />
  }

  return (
    <FormProvider {...methods}>
      <Row>
        <Col sm="4">
          <div className="mb-3">
            <label className="form-label">Title</label>
            <Controller
              name="Titles"
              control={control}
              render={({ field }) => (
                <>
                  <Select
                    {...field}
                    id="Titles"
                    options={titles}
                    getOptionLabel={option => option.Title}
                    getOptionValue={option => option.TitleID}
                    required
                    aria-invalid={!!errors.Titles}
                    classNamePrefix="select2-selection"
                  />
                  {errors?.Titles?.message ? (
                    <FormFeedback type="invalid" className="d-block">
                      {errors?.Titles?.message}
                    </FormFeedback>
                  ) : null}
                </>
              )}
            />
          </div>
          <div className="mb-3">
            <Label>First Name</Label>
            <Controller
              name="FirstName"
              control={control}
              render={({ field }) => (
                <>
                  <Input
                    {...field}
                    id="FirstName"
                    type="text"
                    required
                    invalid={!!errors.FirstName}
                  />
                  {errors?.FirstName?.message ? (
                    <FormFeedback type="invalid" className="d-block">
                      {errors?.FirstName?.message}
                    </FormFeedback>
                  ) : null}
                </>
              )}
            />
          </div>
          <div className="mb-3">
            <Label>Last Name</Label>
            <Controller
              name="LastName"
              control={control}
              render={({ field }) => (
                <>
                  <Input
                    {...field}
                    id="LastName"
                    type="text"
                    required
                    invalid={!!errors.LastName}
                  />
                  {errors?.LastName?.message ? (
                    <FormFeedback type="invalid" className="d-block">
                      {errors?.LastName?.message}
                    </FormFeedback>
                  ) : null}
                </>
              )}
            />
          </div>
          <div className="mb-3">
            <Label>Username</Label>
            <Controller
              name="LoginUserName"
              control={control}
              render={({ field }) => (
                <>
                  <Input
                    {...field}
                    id="LoginUserName"
                    type="text"
                    required
                    disabled={routeParams.id !== "new"}
                    invalid={!!errors.LoginUserName}
                  />
                  {errors?.LoginUserName?.message ? (
                    <FormFeedback type="invalid" className="d-block">
                      {errors?.LoginUserName?.message}
                    </FormFeedback>
                  ) : null}
                </>
              )}
            />
          </div>
          <GenderField />
        </Col>
        <Col sm="4">
          <div className="mb-3">
            <Label>Email</Label>
            <Controller
              name="Email"
              control={control}
              render={({ field }) => (
                <>
                  <Input
                    {...field}
                    id="Email"
                    type="email"
                    required
                    invalid={!!errors.Email}
                  />
                  {errors?.Email?.message ? (
                    <FormFeedback type="invalid" className="d-block">
                      {errors?.Email?.message}
                    </FormFeedback>
                  ) : null}
                </>
              )}
            />
          </div>
          <div className="mb-3">
            <Label>Phone Number</Label>
            <Controller
              name="PhoneNumber"
              control={control}
              render={({ field }) => (
                <>
                  <Input
                    {...field}
                    id="PhoneNumber"
                    type="number"
                    invalid={!!errors.PhoneNumber}
                  />
                  {errors?.PhoneNumber?.message ? (
                    <FormFeedback type="invalid" className="d-block">
                      {errors?.PhoneNumber?.message}
                    </FormFeedback>
                  ) : null}
                </>
              )}
            />
          </div>
          <div className="mb-3">
            <Label>Password</Label>
            <Controller
              name="LoginPassword"
              control={control}
              render={({ field }) => (
                <>
                  <Input {...field} id="LoginPassword" type="password" />
                  {errors?.LoginPassword?.message ? (
                    <FormFeedback type="invalid" className="d-block">
                      {errors?.LoginPassword?.message}
                    </FormFeedback>
                  ) : null}
                </>
              )}
            />
          </div>
          <div className="mb-3">
            <Label>Role</Label>
            <Controller
              name="Roles"
              control={control}
              render={({ field }) => (
                <>
                  <Select
                    {...field}
                    id="Roles"
                    options={roles.filter(x => x.RoleID !== 1)}
                    getOptionLabel={option => option.RoleName}
                    getOptionValue={option => option.RoleID}
                    required
                    aria-invalid={!!errors.Roles}
                    classNamePrefix="select2-selection"
                  />
                  {errors?.Roles?.message ? (
                    <FormFeedback type="invalid" className="d-block">
                      {errors?.Roles?.message}
                    </FormFeedback>
                  ) : null}
                </>
              )}
            />
          </div>
          <div className="mb-3">
            <Label>Active</Label>
            <div>
              <Controller
                name="IsActive"
                control={control}
                render={({ field }) => (
                  <>
                    <Switch
                      {...field}
                      id="IsActive"
                      checked={field.value}
                      uncheckedIcon={<Offsymbol />}
                      checkedIcon={<OnSymbol />}
                      className="me-1 mb-sm-8 mb-2"
                      onColor="#626ed4"
                    />
                  </>
                )}
              />
            </div>
          </div>
        </Col>
        <Col sm="4">
          <div className="mb-3">
            <FileUpload ControlID="Signature" Accept={ValidExtensions} />
            <div className="mt-3">
              {getValues("Attachments") &&
                (
                  getValues("Attachments").filter(x => x.Active === true) || []
                ).map((attachment, e) => {
                  return (
                    <div key={"Attachment" + e}>
                      <div className="d-flex align-start mb-2">
                        <div className="flex-grow-1">
                          <img
                            src={getSignatureImagePath(
                              attachment?.RowID,
                              attachment?.FileName
                            )}
                            style={{ maxHeight: 75 }}
                            alt="Signature"
                          />
                        </div>
                        {/* <Link
                          to="#"
                          className="text-muted"
                          onClick={() => {
                            downloadFile(attachment)
                          }}
                        >
                          <i
                            className="mdi mdi-download font-size-18"
                            id="downloadtooltip"
                          />
                          <UncontrolledTooltip
                            placement="top"
                            target="downloadtooltip"
                          >
                            Download
                          </UncontrolledTooltip>
                        </Link> */}
                        <Link
                          to="#"
                          className="text-danger"
                          onClick={() => {
                            removeFile(attachment?.AttachmentID)
                          }}
                        >
                          <i
                            className="mdi mdi-delete font-size-18"
                            id="deletetooltip"
                          />
                          <UncontrolledTooltip
                            placement="top"
                            target="deletetooltip"
                          >
                            Delete
                          </UncontrolledTooltip>
                        </Link>
                      </div>
                    </div>
                  )
                })}
            </div>
          </div>
          <LocationField />
        </Col>
      </Row>
      <div className="d-flex flex-wrap gap-2">
        <Button color="success" className="btn" onClick={handleSave}>
          {routeParams.id === "new" ? "Save" : "Update"}
        </Button>
        <Button
          type="submit"
          color="secondary"
          onClick={onCancelClick}
          className=""
        >
          Cancel
        </Button>
      </div>
    </FormProvider>
  )
}
export default BasicInfoTab
