import {
  GET_OBSERVATIONS_SUCCESS,
  GET_OBSERVATIONS_FAIL,
  GET_OBSERVATION_DETAIL_SUCCESS,
  GET_OBSERVATION_DETAIL_FAIL,
  DELETE_OBSERVATION_SUCCESS,
  DELETE_OBSERVATION_FAIL,
  ADD_OBSERVATION_SUCCESS,
  ADD_OBSERVATION_FAIL,
  UPDATE_OBSERVATION_SUCCESS,
  UPDATE_OBSERVATION_FAIL,
} from "./actionTypes"

const INIT_STATE = {
  observations: [],
  observation: {},
  error: {},
  loading: true,
}

const Observation = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_OBSERVATIONS_SUCCESS:
      return {
        ...state,
        observations: action.payload,
        loading: true,
      }
    case GET_OBSERVATIONS_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case GET_OBSERVATION_DETAIL_SUCCESS:
      return {
        ...state,
        observation: action.payload,
        loading: true,
      }
    case GET_OBSERVATION_DETAIL_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case DELETE_OBSERVATION_SUCCESS: {
      return {
        ...state,
        observations: state.observations.filter(
          b => b.ObservationID !== action.payload
        ),
      }
    }
    case DELETE_OBSERVATION_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case ADD_OBSERVATION_SUCCESS:
      return {
        ...state,
        observations: action.payload,
      }
    case ADD_OBSERVATION_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case UPDATE_OBSERVATION_SUCCESS:
      return {
        ...state,
        observations: action.payload,
      }
    case UPDATE_OBSERVATION_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    default:
      return state
  }
}

export default Observation
