import { call, put, takeEvery } from "redux-saga/effects"
import Moment from "moment"

// Staff Redux States
import { GET_PROFILE } from "./actionTypes"
import { getProfileSuccess, getProfileFail } from "./actions"

//Include Both Helper File with needed methods
import { getUserProfile } from "helpers/backend_helper"

// toast
import { toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"

function* fetchUserProfile({ payload: rowID }) {
  try {
    const response = yield call(getUserProfile, rowID)
    yield put(getProfileSuccess(response))
  } catch (error) {
    yield put(getProfileFail(error))
  }
}

function* userSaga() {
  yield takeEvery(GET_PROFILE, fetchUserProfile)
}

export default userSaga
