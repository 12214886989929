import { all, fork } from "redux-saga/effects"

//public
import AccountSaga from "./auth/register/saga"
import AuthSaga from "./auth/login/saga"
import ForgetSaga from "./auth/forgetpwd/saga"
import ProfileSaga from "./auth/profile/saga"
import LayoutSaga from "./layout/saga"

import RoleSaga from "./role/saga"
import StaffSaga from "./staff/saga"
import NavigationSaga from "./navigation/saga"
import helperSaga from "./helper/saga"
import PatientSaga from "./patient/saga"
import ObservationSaga from "./observation/saga"
import userSaga from "./user/saga"

export default function* rootSaga() {
  yield all([
    //public
    fork(AccountSaga),
    fork(AuthSaga),
    fork(ForgetSaga),
    fork(ProfileSaga),
    fork(LayoutSaga),
    fork(RoleSaga),
    fork(StaffSaga),
    fork(NavigationSaga),
    fork(helperSaga),
    fork(PatientSaga),
    fork(ObservationSaga),
    fork(userSaga),
  ])
}
