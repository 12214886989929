import {
  GET_STAFFS,
  GET_STAFFS_FAIL,
  GET_STAFFS_SUCCESS,
  DELETE_STAFF,
  DELETE_STAFF_FAIL,
  DELETE_STAFF_SUCCESS,
  ADD_NEW_STAFF,
  ADD_STAFF_SUCCESS,
  ADD_STAFF_FAIL,
  GET_STAFF_DETAIL,
  GET_STAFF_DETAIL_SUCCESS,
  GET_STAFF_DETAIL_FAIL,
  UPDATE_STAFF,
  UPDATE_STAFF_SUCCESS,
  UPDATE_STAFF_FAIL,
  UPDATE_STAFF_PHOTO,
  UPDATE_STAFF_PHOTO_SUCCESS,
  UPDATE_STAFF_PHOTO_FAIL,
  RESET_STAFF_SUCCESS,
  RESET_STAFF,
  GET_PAYRATES,
  GET_PAYRATES_SUCCESS,
  GET_PAYRATES_FAIL,
  DELETE_PAYRATE,
  DELETE_PAYRATE_SUCCESS,
  DELETE_PAYRATE_FAIL,
  ADD_NEW_PAYRATE,
  ADD_PAYRATE_SUCCESS,
  ADD_PAYRATE_FAIL,
  GET_PAYRATE_DETAIL,
  GET_PAYRATE_DETAIL_SUCCESS,
  GET_PAYRATE_DETAIL_FAIL,
  UPDATE_PAYRATE,
  UPDATE_PAYRATE_SUCCESS,
  UPDATE_PAYRATE_FAIL,
  RESET_PAYRATE,
  RESET_PAYRATE_SUCCESS,
  GET_EMPLOYMENT_TYPES,
  GET_EMPLOYMENT_TYPES_SUCCESS,
  GET_EMPLOYMENT_TYPES_FAIL,
  GET_CLASSIFICATION_TYPES,
  GET_CLASSIFICATION_TYPES_SUCCESS,
  GET_CLASSIFICATION_TYPES_FAIL,
  GET_STAFF_AVAILABILITY,
  GET_STAFF_AVAILABILITY_SUCCESS,
  GET_STAFF_AVAILABILITY_FAIL,
  ADD_STAFF_AVAILABILITY,
  ADD_STAFF_AVAILABILITY_SUCCESS,
  ADD_STAFF_AVAILABILITY_FAIL,
  GET_STAFF_PROFILE,
  GET_STAFF_PROFILE_SUCCESS,
  GET_STAFF_PROFILE_FAIL,
  ADD_STAFF_PROFILE,
  ADD_STAFF_PROFILE_SUCCESS,
  ADD_STAFF_PROFILE_FAIL,
  GET_HAPPENING_TYPES,
  GET_HAPPENING_TYPES_SUCCESS,
  GET_HAPPENING_TYPES_FAIL,
  GET_STAFF_HAPPENING,
  GET_STAFF_HAPPENING_SUCCESS,
  GET_STAFF_HAPPENING_FAIL,
  ADD_STAFF_HAPPENING,
  ADD_STAFF_HAPPENING_SUCCESS,
  ADD_STAFF_HAPPENING_FAIL,
  DELETE_STAFF_HAPPENING,
  DELETE_STAFF_HAPPENING_SUCCESS,
  DELETE_STAFF_HAPPENING_FAIL,
  UPDATE_STAFF_HAPPENING,
  UPDATE_STAFF_HAPPENING_SUCCESS,
  UPDATE_STAFF_HAPPENING_FAIL,
  GET_STAFF_HAPPENING_DETAIL,
  GET_STAFF_HAPPENING_DETAIL_SUCCESS,
  GET_STAFF_HAPPENING_DETAIL_FAIL,
  GET_HOUR_TYPES,
  GET_HOUR_TYPES_SUCCESS,
  GET_HOUR_TYPES_FAIL,
  GET_GENDERS,
  GET_GENDERS_SUCCESS,
  GET_GENDERS_FAIL,
  GET_TITLES,
  GET_TITLES_SUCCESS,
  GET_TITLES_FAIL,
} from "./actionTypes"

export const getStaffs = () => ({
  type: GET_STAFFS,
})

export const getStaffsSuccess = staffs => ({
  type: GET_STAFFS_SUCCESS,
  payload: staffs,
})

export const getStaffsFail = error => ({
  type: GET_STAFFS_FAIL,
  payload: error,
})

export const deleteStaff = staff => ({
  type: DELETE_STAFF,
  payload: staff,
})

export const deleteStaffSuccess = staff => ({
  type: DELETE_STAFF_SUCCESS,
  payload: staff,
})

export const deleteStaffFail = error => ({
  type: DELETE_STAFF_FAIL,
  payload: error,
})

export const addNewStaff = staff => ({
  type: ADD_NEW_STAFF,
  payload: staff,
})

export const addStaffSuccess = staff => ({
  type: ADD_STAFF_SUCCESS,
  payload: staff,
})

export const addStaffFail = error => ({
  type: ADD_STAFF_FAIL,
  payload: error,
})

export const getStaffDetail = staffId => ({
  type: GET_STAFF_DETAIL,
  staffId,
})

export const getStaffDetailSuccess = staff => ({
  type: GET_STAFF_DETAIL_SUCCESS,
  payload: staff,
})

export const getStaffDetailFail = error => ({
  type: GET_STAFF_DETAIL_FAIL,
  payload: error,
})

export const updateStaff = staff => ({
  type: UPDATE_STAFF,
  payload: staff,
})

export const updateStaffSuccess = staff => ({
  type: UPDATE_STAFF_SUCCESS,
  payload: staff,
})

export const updateStaffFail = error => ({
  type: UPDATE_STAFF_FAIL,
  payload: error,
})

export const updateStaffPhoto = staff => ({
  type: UPDATE_STAFF_PHOTO,
  payload: staff,
})
export const updateStaffPhotoSuccess = staff => ({
  type: UPDATE_STAFF_PHOTO_SUCCESS,
  payload: staff,
})

export const updateStaffPhotoFail = error => ({
  type: UPDATE_STAFF_PHOTO_FAIL,
  payload: error,
})

export const resetStaff = () => ({
  type: RESET_STAFF,
})

export const resetStaffSuccess = staff => ({
  type: RESET_STAFF_SUCCESS,
  payload: staff,
})

// payrate
export const getPayrates = payrates => ({
  type: GET_PAYRATES,
  payload: payrates,
})

export const getPayratesSuccess = payrates => ({
  type: GET_PAYRATES_SUCCESS,
  payload: payrates,
})

export const getPayratesFail = error => ({
  type: GET_PAYRATES_FAIL,
  payload: error,
})

export const deletePayrate = payrates => ({
  type: DELETE_PAYRATE,
  payload: payrates,
})

export const deletePayrateSuccess = payrate => ({
  type: DELETE_PAYRATE_SUCCESS,
  payload: payrate,
})

export const deletePayrateFail = error => ({
  type: DELETE_PAYRATE_FAIL,
  payload: error,
})

export const addNewPayrate = payrate => ({
  type: ADD_NEW_PAYRATE,
  payload: payrate,
})

export const addPayrateSuccess = payrate => ({
  type: ADD_PAYRATE_SUCCESS,
  payload: payrate,
})

export const addPayrateFail = error => ({
  type: ADD_PAYRATE_FAIL,
  payload: error,
})
export const getPayrateDetail = payrateID => ({
  type: GET_PAYRATE_DETAIL,
  payrateID,
})

export const getPayrateDetailSuccess = payrate => ({
  type: GET_PAYRATE_DETAIL_SUCCESS,
  payload: payrate,
})

export const getPayrateDetailFail = error => ({
  type: GET_PAYRATE_DETAIL_FAIL,
  payload: error,
})

export const updatePayrate = payrate => ({
  type: UPDATE_PAYRATE,
  payload: payrate,
})

export const updatePayrateSuccess = payrate => ({
  type: UPDATE_PAYRATE_SUCCESS,
  payload: payrate,
})

export const updatePayrateFail = error => ({
  type: UPDATE_PAYRATE_FAIL,
  payload: error,
})

export const resetPayrate = () => ({
  type: RESET_PAYRATE,
})

export const resetPayrateSuccess = payrate => ({
  type: RESET_PAYRATE_SUCCESS,
  payload: payrate,
})

export const getEmploymentTypes = () => ({
  type: GET_EMPLOYMENT_TYPES,
})

export const getEmploymentTypesSuccess = employmentTypes => ({
  type: GET_EMPLOYMENT_TYPES_SUCCESS,
  payload: employmentTypes,
})

export const getEmploymentTypesFail = error => ({
  type: GET_EMPLOYMENT_TYPES_FAIL,
  payload: error,
})

export const getClassificationTypes = () => ({
  type: GET_CLASSIFICATION_TYPES,
})

export const getClassificationTypesSuccess = classificationTypes => ({
  type: GET_CLASSIFICATION_TYPES_SUCCESS,
  payload: classificationTypes,
})

export const getClassificationTypesFail = error => ({
  type: GET_CLASSIFICATION_TYPES_FAIL,
  payload: error,
})

export const getStaffAvailability = staffID => ({
  type: GET_STAFF_AVAILABILITY,
  staffID,
})

export const getStaffAvailabilitySuccess = staffavailability => ({
  type: GET_STAFF_AVAILABILITY_SUCCESS,
  payload: staffavailability,
})

export const getStaffAvailabilityFail = error => ({
  type: GET_STAFF_AVAILABILITY_FAIL,
  payload: error,
})

export const addStaffAvailability = (staffID, staffavailability) => ({
  type: ADD_STAFF_AVAILABILITY,
  payload: { staffID, staffavailability },
})

export const addStaffAvailabilitySuccess = staffavailability => ({
  type: ADD_STAFF_AVAILABILITY_SUCCESS,
  payload: staffavailability,
})

export const addStaffAvailabilityFail = error => ({
  type: ADD_STAFF_AVAILABILITY_FAIL,
  payload: error,
})

export const getStaffProfile = staffId => ({
  type: GET_STAFF_PROFILE,
  staffId,
})

export const getStaffProfileSuccess = staffprofile => ({
  type: GET_STAFF_PROFILE_SUCCESS,
  payload: staffprofile,
})

export const getStaffProfileFail = error => ({
  type: GET_STAFF_PROFILE_FAIL,
  payload: error,
})

export const addStaffProfile = (staffId, staffprofile) => ({
  type: ADD_STAFF_PROFILE,
  payload: { staffId, staffprofile },
})

export const addStaffProfileSuccess = staffprofile => ({
  type: ADD_STAFF_PROFILE_SUCCESS,
  payload: staffprofile,
})
export const addStaffProfileFail = error => ({
  type: ADD_STAFF_PROFILE_FAIL,
  payload: error,
})

export const getHappeningTypes = () => ({
  type: GET_HAPPENING_TYPES,
})

export const getHappeningTypesSuccess = happeningTypes => ({
  type: GET_HAPPENING_TYPES_SUCCESS,
  payload: happeningTypes,
})

export const getHappeningTypesFail = error => ({
  type: GET_HAPPENING_TYPES_FAIL,
  payload: error,
})

export const getStaffHappenings = (staffID, filterDate) => ({
  type: GET_STAFF_HAPPENING,
  payload: { staffID, filterDate },
})

export const getStaffHappeningsSuccess = stafHappenings => ({
  type: GET_STAFF_HAPPENING_SUCCESS,
  payload: stafHappenings,
})

export const getStaffHappeningsFail = error => ({
  type: GET_STAFF_HAPPENING_FAIL,
  payload: error,
})

export const addStaffHappening = staffHappening => ({
  type: ADD_STAFF_HAPPENING,
  payload: staffHappening,
})

export const addStaffHappeningSuccess = staffHappening => ({
  type: ADD_STAFF_HAPPENING_SUCCESS,
  payload: staffHappening,
})

export const addStaffHappeningFail = error => ({
  type: ADD_STAFF_HAPPENING_FAIL,
  payload: error,
})

export const getStaffHappeningDetail = staffHappeningID => ({
  type: GET_STAFF_HAPPENING_DETAIL,
  staffHappeningID,
})

export const getStaffHappeningDetailSuccess = staffHappening => ({
  type: GET_STAFF_HAPPENING_DETAIL_SUCCESS,
  payload: staffHappening,
})

export const getStaffHappeningDetailFail = error => ({
  type: GET_STAFF_HAPPENING_DETAIL_FAIL,
  payload: error,
})

export const updateStaffHappening = staffHappening => ({
  type: UPDATE_STAFF_HAPPENING,
  payload: staffHappening,
})

export const updateStaffHappeningSuccess = staffHappening => ({
  type: UPDATE_STAFF_HAPPENING_SUCCESS,
  payload: staffHappening,
})

export const updateStaffHappeningFail = error => ({
  type: UPDATE_STAFF_HAPPENING_FAIL,
  payload: error,
})

export const deleteStaffHappening = staffHappening => ({
  type: DELETE_STAFF_HAPPENING,
  payload: staffHappening,
})

export const deleteStaffHappeningSuccess = staffHappening => ({
  type: DELETE_STAFF_HAPPENING_SUCCESS,
  payload: staffHappening,
})

export const deleteStaffHappeningFail = error => ({
  type: DELETE_STAFF_HAPPENING_FAIL,
  payload: error,
})

export const getHourTypes = () => ({
  type: GET_HOUR_TYPES,
})

export const getHourTypesSuccess = hourTypes => ({
  type: GET_HOUR_TYPES_SUCCESS,
  payload: hourTypes,
})

export const getHourTypesFail = error => ({
  type: GET_HOUR_TYPES_FAIL,
  payload: error,
})

export const getGenders = () => ({
  type: GET_GENDERS,
})

export const getGendersSuccess = genders => ({
  type: GET_GENDERS_SUCCESS,
  payload: genders,
})

export const getGendersFail = error => ({
  type: GET_GENDERS_FAIL,
  payload: error,
})

export const getTitles = () => ({
  type: GET_TITLES,
})

export const getTitlesSuccess = titles => ({
  type: GET_TITLES_SUCCESS,
  payload: titles,
})

export const getTitlesFail = error => ({
  type: GET_TITLES_FAIL,
  payload: error,
})
