import React, { useEffect, useMemo, useState } from "react"
import PropTypes from "prop-types"
import { Link, useNavigate } from "react-router-dom"
import "../../../node_modules/bootstrap/dist/css/bootstrap.min.css"
import TableContainer from "../../components/Common/TableContainer"
import Moment from "moment"

//import components
import Breadcrumbs from "../../components/Common/Breadcrumb"
import DeleteModal from "../../components/Common/DeleteModal"

import {
  getObservations as onGetObservations,
  deleteObservation as onDeleteObservation,
} from "store/actions"

//redux
import { useSelector, useDispatch } from "react-redux"

import {
  Badge,
  Col,
  Row,
  UncontrolledTooltip,
  Card,
  CardBody,
} from "reactstrap"
import Spinners from "components/Common/Spinner"
import { ToastContainer } from "react-toastify"
import { isEmpty } from "lodash"

function Observation() {
  const navigate = useNavigate()
  const dispatch = useDispatch()

  const { observations, loading } = useSelector(state => state.observation)

  const [isLoading, setLoading] = useState(loading)
  const [deleteModal, setDeleteModal] = useState(false)
  const [observation, setObservation] = useState(null)

  useEffect(() => {
    if (observations && !observations.length) {
      dispatch(onGetObservations())
    }
  }, [dispatch, observations])

  const handleAddClick = () => {
    navigate("/observation/new")
  }

  const onClickDelete = arg => {
    setObservation(arg)
    setDeleteModal(true)
  }

  const handleDelete = () => {
    if (observation && observation.ObservationID) {
      dispatch(onDeleteObservation(observation.ObservationID))
      setDeleteModal(false)
    }
  }

  const handleValidDate = date => {
    return Moment(new Date(date)).format("DD MMM Y")
  }

  const columns = useMemo(
    () => [
      {
        Header: "Doctor",
        accessor: "Doctor",
        Cell: cellProps => {
          return (
            <>
              <h5 className="font-size-14 mb-1">
                {cellProps.row.original.Doctor}
              </h5>
              <p className="text-muted mb-0">
                {handleValidDate(cellProps.row.original.ObservationDate)}
              </p>
            </>
          )
        },
      },
      {
        Header: "Patient",
        accessor: "Patient",
        Cell: cellProps => {
          return (
            <>
              <h5 className="font-size-14 mb-1">
                {cellProps.row.original.Patient}
              </h5>
            </>
          )
        },
      },
      {
        Header: "Local Authority",
        accessor: "LocalAuthority",
        Cell: cellProps => {
          return (
            <>
              <h5 className="font-size-14 mb-1">
                {cellProps.row.original.LocalAuthority}
              </h5>
            </>
          )
        },
      },
      {
        Header: "Route",
        accessor: "Route",
        Cell: cellProps => {
          return (
            <>
              <h5 className="font-size-14 mb-1">
                {cellProps.row.original.Route}
              </h5>
            </>
          )
        },
      },
      {
        Header: "Created By",
        accessor: "CreatedBy",
        Cell: cellProps => {
          return (
            <>
              <span className="font-size-14 mb-1">
                {cellProps.row.original.CreatedByName}
              </span>
              <p className="text-muted mb-0">
                {handleValidDate(cellProps.row.original.CreatedDate)}
              </p>
            </>
          )
        },
      },
      {
        Header: "Action",
        Cell: cellProps => {
          return (
            <div className="d-flex gap-3">
              <Link
                to={"/observation-report/" + cellProps.row.original.RowID}
                className="text-success"
              >
                <i
                  className="mdi mdi-chart-bar font-size-18"
                  id="reporttooltip"
                />
                <UncontrolledTooltip placement="top" target="reporttooltip">
                  Report
                </UncontrolledTooltip>
              </Link>
              <Link
                to={"/observation/" + cellProps.row.original.RowID}
                className="text-success"
              >
                <i className="mdi mdi-pencil font-size-18" id="edittooltip" />
                <UncontrolledTooltip placement="top" target="edittooltip">
                  Edit
                </UncontrolledTooltip>
              </Link>
              <Link
                to="#"
                className="text-danger"
                onClick={() => {
                  onClickDelete(cellProps.row.original)
                }}
              >
                <i className="mdi mdi-delete font-size-18" id="deletetooltip" />
                <UncontrolledTooltip placement="top" target="deletetooltip">
                  Delete
                </UncontrolledTooltip>
              </Link>
            </div>
          )
        },
      },
    ],
    []
  )
  return (
    <React.Fragment>
      {deleteModal && (
        <DeleteModal
          show={deleteModal}
          onDeleteClick={handleDelete}
          onCloseClick={() => setDeleteModal(false)}
        />
      )}
      <div className="page-content">
        <div className="container-fluid">
          <Breadcrumbs title="Observations" breadcrumbItem="Observation List" />
          {isLoading ? (
            <Spinners setLoading={setLoading} />
          ) : (
            <Row>
              <Col xs="12">
                <Card>
                  <CardBody>
                    <TableContainer
                      columns={columns}
                      data={observations}
                      isGlobalFilter={true}
                      isAddOption={true}
                      addOptionText="Add Observation"
                      handleAddClick={handleAddClick}
                      customPageSize={10}
                      isPagination={true}
                      filterable={true}
                      iscustomPageSizeOptions={true}
                      tableClass="align-middle table-nowrap table-check"
                      theadClass="table-light"
                      pagination="pagination pagination-rounded justify-content-end mb-2"
                    />
                  </CardBody>
                </Card>
              </Col>
            </Row>
          )}
        </div>
      </div>
      <ToastContainer />
    </React.Fragment>
  )
}
Observation.propTypes = {
  preGlobalFilteredRows: PropTypes.any,
}

export default Observation
