import { call, put, takeEvery } from "redux-saga/effects"
import Moment from "moment"

// Meal Redux States
import {
  GET_OBSERVATIONS,
  GET_OBSERVATION_DETAIL,
  ADD_NEW_OBSERVATION,
  DELETE_OBSERVATION,
  UPDATE_OBSERVATION,
} from "./actionTypes"
import {
  getObservationsSuccess,
  getObservationsFail,
  getObservationDetailSuccess,
  getObservationDetailFail,
  addObservationSuccess,
  addObservationFail,
  deleteObservationSuccess,
  deleteObservationFail,
  updateObservationSuccess,
  updateObservationFail,
} from "./actions"

//Include Both Helper File with needed methods
import {
  getObservations,
  getObservationDetail,
  deleteObservation,
  addNewObservation,
  updateObservation,
} from "helpers/backend_helper"

// toast
import { toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"

function* fetchObservations() {
  try {
    const response = yield call(getObservations)
    yield put(getObservationsSuccess(response))
  } catch (error) {
    yield put(getObservationsFail(error))
  }
}

function* onDeleteObservation({ payload: observationId }) {
  try {
    const response = yield call(deleteObservation, observationId)
    yield put(deleteObservationSuccess(response))
    toast.success("Observation deleted successfully", { autoClose: 2000 })
  } catch (error) {
    yield put(deleteObservationFail(error))
    toast.error("Observation deletion failed", { autoClose: 2000 })
  }
}

function* onAddNewObservation({ payload: observation }) {
  try {
    const response = yield call(addNewObservation, observation)
    yield put(addObservationSuccess(response[0]))
    toast.success("Observation added successfully", { autoClose: 2000 })
  } catch (error) {
    yield put(addObservationFail(error))
    toast.error("Observation addition failed", { autoClose: 2000 })
  }
}

const getParsedObservationData = result => {
  return {
    ...result,
    ObservationDate: result.ObservationDate
      ? Moment(result.ObservationDate).format("DD MMM, YYYY")
      : "",
    Doctors: {
      UserID: result.DoctorID,
      Name: result.Doctor,
    },
    Patients: {
      PatientID: result.PatientID,
      Name: result.Patient,
    },
    LocalAuthorities: {
      LocalAuthorityID: result.LocalAuthorityID,
      LocalAuthorityName: result.LocalAuthority,
    },
    Routes: {
      RouteID: result.RouteID,
      RouteName: result.Route,
    },
    RouteKeyPoints: JSON.parse(result.RouteKeyPointData),
  }
}

function* fetchObservationDetail({ observationId }) {
  try {
    const response = yield call(getObservationDetail, observationId)

    yield put(
      getObservationDetailSuccess(getParsedObservationData(response[0]))
    )
  } catch (error) {
    yield put(getObservationDetailFail(error))
  }
}

function* onUpdateObservation({ payload: observation }) {
  try {
    const response = yield call(updateObservation, observation)
    yield put(updateObservationSuccess(getParsedObservationData(response[0])))
    toast.success("Observation updated successfully", { autoClose: 2000 })
  } catch (error) {
    yield put(updateObservationFail(error))
    toast.error("Observation updation failed", { autoClose: 2000 })
  }
}

function* observationSaga() {
  yield takeEvery(GET_OBSERVATIONS, fetchObservations)
  yield takeEvery(GET_OBSERVATION_DETAIL, fetchObservationDetail)
  yield takeEvery(DELETE_OBSERVATION, onDeleteObservation)
  yield takeEvery(ADD_NEW_OBSERVATION, onAddNewObservation)
  yield takeEvery(UPDATE_OBSERVATION, onUpdateObservation)
}

export default observationSaga
