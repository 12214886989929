import { call, put, takeEvery } from "redux-saga/effects"

// Staff Redux States
import {
  GET_STAFFS,
  DELETE_STAFF,
  ADD_NEW_STAFF,
  GET_STAFF_DETAIL,
  UPDATE_STAFF,
  RESET_STAFF,
  GET_GENDERS,
  GET_TITLES,
} from "./actionTypes"
import {
  getStaffsFail,
  getStaffsSuccess,
  deleteStaffSuccess,
  deleteStaffFail,
  addStaffSuccess,
  addStaffFail,
  getStaffDetailSuccess,
  getStaffDetailFail,
  updateStaffSuccess,
  updateStaffFail,
  resetStaffSuccess,
  getGendersSuccess,
  getGendersFail,
  getTitlesSuccess,
  getTitlesFail,
} from "./actions"

//Include Both Helper File with needed methods
import {
  getStaffs,
  deleteStaff,
  addNewStaff,
  getStaffDetail,
  updateStaff,
  getGenders,
  getTitles,
} from "helpers/backend_helper"

// toast
import { toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"
function* fetchStaffs() {
  try {
    let response = yield call(getStaffs)

    response = response.map(x => ({
      ...x,
      Address: x.Address ?? "",
      CountryID: x.CountryID ?? 0,
      Country: x.Country ?? "",
      StateID: x.StateID ?? 0,
      State: x.State ?? "",
      CityID: x.CityID ?? 0,
      City: x.City ?? "",
      PostCode: x.PostCode ?? "",
      Attachments: x.Attachments ?? [],
      Countries: [],
      States: [],
      Cities: [],
      Titles: [],
      TitleID: x.TitleID ?? 0,
      Title: x.Title ?? "",
      GenderID: x.GenderID ?? 0,
      Gender: x.Gender ?? "",
      Genders: [],
      RoleID: x.RoleID ?? 0,
      Role: x.Role ?? "",
      Roles: [],
    }))
    yield put(getStaffsSuccess(response))
  } catch (error) {
    yield put(getStaffsFail(error))
  }
}

function* onDeleteStaff({ payload: staff }) {
  try {
    const response = yield call(deleteStaff, staff)
    yield put(deleteStaffSuccess(response))
    toast.success("Staff deleted successfully", { autoClose: 2000 })
  } catch (error) {
    yield put(deleteStaffFail(error))
    toast.error("Staff deletion failed", { autoClose: 2000 })
  }
}

function* onAddNewStaff({ payload: staff }) {
  try {
    var formData = new FormData()
    let i = 1
    staff?.Signature?.forEach(item => {
      formData.append("Attachment" + i, item)
      i++
    })
    formData.append("Data", JSON.stringify(staff))

    const response = yield call(addNewStaff, formData)
    yield put(addStaffSuccess(response))
    toast.success("User added successfully", { autoClose: 2000 })
  } catch (error) {
    yield put(addStaffFail(error))
    toast.error("User addition failed", { autoClose: 2000 })
  }
}

function* fetchStaffDetail({ staffId }) {
  try {
    const response = yield call(getStaffDetail, staffId)
    let result = response

    result = {
      ...result,
      Roles: {
        RoleID: result.RoleID,
        RoleName: result.RoleName,
      },
      PhoneNumber: result.PhoneNumber ?? "",
      Titles: {
        TitleID: result.TitleID ?? "",
        Title: result.Title ?? "Select...",
      },
      Genders: {
        GenderID: result.GenderID ?? "",
        Gender: result.Gender ?? "Select...",
      },
      Countries: {
        CountryID: Number(result.CountryID) ?? 0,
        Name: result.Country,
      },
      States: {
        StateID: Number(result.StateID) ?? 0,
        Name: result.State,
      },
      Cities: {
        CityID: Number(result.CityID) ?? 0,
        Name: result.City,
      },
    }
    console.log(result)
    yield put(getStaffDetailSuccess(result))
  } catch (error) {
    yield put(getStaffDetailFail(error))
  }
}

function* onUpdateStaff({ payload: staff }) {
  try {
    var formData = new FormData()
    let i = 1
    staff?.Signature?.forEach(item => {
      formData.append("Attachment" + i, item)
      i++
    })
    formData.append("Data", JSON.stringify(staff))

    const response = yield call(updateStaff, formData)

    yield put(updateStaffSuccess(response))
    toast.success("User updated successfully", { autoClose: 2000 })
  } catch (error) {
    yield put(updateStaffFail(error))
    toast.error("User updation failed", { autoClose: 2000 })
  }
}

function* onResetStaff() {
  try {
    yield put(resetStaffSuccess({}))
  } catch (error) {}
}

function* fetchGenders() {
  try {
    const response = yield call(getGenders)
    yield put(getGendersSuccess(response))
  } catch (error) {
    yield put(getGendersFail(error))
  }
}

function* fetchTitles() {
  try {
    const response = yield call(getTitles)
    yield put(getTitlesSuccess(response))
  } catch (error) {
    yield put(getTitlesFail(error))
  }
}

function* staffSaga() {
  yield takeEvery(GET_STAFFS, fetchStaffs)
  yield takeEvery(DELETE_STAFF, onDeleteStaff)
  yield takeEvery(ADD_NEW_STAFF, onAddNewStaff)
  yield takeEvery(GET_STAFF_DETAIL, fetchStaffDetail)
  yield takeEvery(UPDATE_STAFF, onUpdateStaff)
  yield takeEvery(RESET_STAFF, onResetStaff)

  yield takeEvery(GET_GENDERS, fetchGenders)
  yield takeEvery(GET_TITLES, fetchTitles)
}

export default staffSaga
