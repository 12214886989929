import {
  GET_PATIENTS_SUCCESS,
  GET_PATIENTS_FAIL,
  GET_PATIENT_DETAIL_SUCCESS,
  GET_PATIENT_DETAIL_FAIL,
  DELETE_PATIENT_SUCCESS,
  DELETE_PATIENT_FAIL,
  ADD_PATIENT_SUCCESS,
  ADD_PATIENT_FAIL,
  UPDATE_PATIENT_SUCCESS,
  UPDATE_PATIENT_FAIL,
} from "./actionTypes"

const INIT_STATE = {
  patients: [],
  patient: {},
  error: {},
  loading: true,
}

const Patient = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_PATIENTS_SUCCESS:
      return {
        ...state,
        patients: action.payload,
        loading: true,
      }
    case GET_PATIENTS_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case GET_PATIENT_DETAIL_SUCCESS:
      return {
        ...state,
        patient: action.payload,
        loading: true,
      }
    case GET_PATIENT_DETAIL_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case DELETE_PATIENT_SUCCESS: {
      return {
        ...state,
        patients: state.patients.filter(b => b.PatientID !== action.payload),
      }
    }
    case DELETE_PATIENT_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case ADD_PATIENT_SUCCESS:
      return {
        ...state,
        patients: action.payload,
      }
    case ADD_PATIENT_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case UPDATE_PATIENT_SUCCESS:
      return {
        ...state,
        patients: action.payload,
      }
    case UPDATE_PATIENT_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    default:
      return state
  }
}

export default Patient
