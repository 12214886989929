import { call, put, takeEvery } from "redux-saga/effects"

// Meal Redux States
import {
  GET_PATIENTS,
  GET_PATIENT_DETAIL,
  ADD_NEW_PATIENT,
  DELETE_PATIENT,
  UPDATE_PATIENT,
} from "./actionTypes"
import {
  getPatientsSuccess,
  getPatientsFail,
  getPatientDetailSuccess,
  getPatientDetailFail,
  addPatientSuccess,
  addPatientFail,
  deletePatientSuccess,
  deletePatientFail,
  updatePatientSuccess,
  updatePatientFail,
} from "./actions"

//Include Both Helper File with needed methods
import {
  getPatients,
  getPatientDetail,
  deletePatient,
  addNewPatient,
  updatePatient,
} from "helpers/backend_helper"

// toast
import { toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"

function* fetchPatients() {
  try {
    const response = yield call(getPatients)
    yield put(getPatientsSuccess(response))
  } catch (error) {
    yield put(getPatientsFail(error))
  }
}

function* onDeletePatient({ payload: patientId }) {
  try {
    const response = yield call(deletePatient, patientId)
    yield put(deletePatientSuccess(response))
    toast.success("Patient deleted successfully", { autoClose: 2000 })
  } catch (error) {
    yield put(deletePatientFail(error))
    toast.error("Patient deletion failed", { autoClose: 2000 })
  }
}

function* onAddNewPatient({ payload: patient }) {
  try {
    const response = yield call(addNewPatient, patient)
    yield put(addPatientSuccess(response[0]))
    toast.success("Patient added successfully", { autoClose: 2000 })
  } catch (error) {
    yield put(addPatientFail(error))
    toast.error("Patient addition failed", { autoClose: 2000 })
  }
}

const getParsedPatientData = result => {
  return {
    ...result,
    Countries: {
      CountryID: Number(result.CountryID) ?? 0,
      Name: result.Country,
    },
    States: {
      StateID: Number(result.StateID) ?? 0,
      Name: result.State,
    },
    Cities: {
      CityID: Number(result.CityID) ?? 0,
      Name: result.City,
    },
    PhoneNumber: result.PhoneNumber ?? "",
    Titles: {
      TitleID: result.TitleID ?? "",
      Title: result.Title ?? "Select...",
    },
    Genders: {
      GenderID: result.GenderID ?? "",
      Gender: result.Gender ?? "Select...",
    },
  }
}

function* fetchPatientDetail({ patientId }) {
  try {
    const response = yield call(getPatientDetail, patientId)

    yield put(getPatientDetailSuccess(getParsedPatientData(response[0])))
  } catch (error) {
    yield put(getPatientDetailFail(error))
  }
}

function* onUpdatePatient({ payload: patient }) {
  try {
    const response = yield call(updatePatient, patient)
    yield put(updatePatientSuccess(getParsedPatientData(response[0])))
    toast.success("Patient updated successfully", { autoClose: 2000 })
  } catch (error) {
    yield put(updatePatientFail(error))
    toast.error("Patient updation failed", { autoClose: 2000 })
  }
}

function* patientSaga() {
  yield takeEvery(GET_PATIENTS, fetchPatients)
  yield takeEvery(GET_PATIENT_DETAIL, fetchPatientDetail)
  yield takeEvery(DELETE_PATIENT, onDeletePatient)
  yield takeEvery(ADD_NEW_PATIENT, onAddNewPatient)
  yield takeEvery(UPDATE_PATIENT, onUpdatePatient)
}

export default patientSaga
