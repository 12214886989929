export const getImagePath = url => {
  const path = process.env.REACT_APP_API_ROOT_URL + url
  return path
}

export const getSignatureImagePath = (rowID, fileName) => {
  const path =
    process.env.REACT_APP_API_ROOT_URL +
    `/APIImageHandler.ashx?Path=_files/Signature/_${rowID}&Name=${fileName}`
  return path
}

export const getUserImagePath = (rowID, fileName) => {
  const path =
    process.env.REACT_APP_API_ROOT_URL +
    `/APIImageHandler.ashx?Path=_files/User/_${rowID}&Name=${fileName}`
  return path
}
