import { call, put, takeEvery } from "redux-saga/effects"

// Meal Redux States
import {
  GET_COUNTRIES,
  GET_STATES,
  GET_CITIES,
  GET_ROUTES,
  GET_KEY_POINTS,
  GET_LOCAL_AUTHORITIES,
} from "./actionTypes"
import {
  getCountriesSuccess,
  getCountriesFail,
  getStatesSuccess,
  getStatesFail,
  getCitiesSuccess,
  getCitiesFail,
  getRoutesSuccess,
  getRoutesFail,
  getKeyPointsSuccess,
  getKeyPointsFail,
  getLocalAuthoritiesSuccess,
  getLocalAuthoritiesFail,
} from "./actions"

//Include Both Helper File with needed methods
import {
  getCountries,
  getStates,
  getCities,
  getRoutes,
  getKeyPoints,
  getLocalAuthorities,
} from "helpers/backend_helper"

// toast
import { toast } from "react-toastify"
import "react-toastify/dist/ReactToastify.css"

function* fetchCountries() {
  try {
    const response = yield call(getCountries)
    yield put(getCountriesSuccess(response))
  } catch (error) {
    yield put(getCountriesFail(error))
  }
}

function* fetchStates({ payload: countryID }) {
  try {
    const response = yield call(getStates, countryID)
    yield put(getStatesSuccess(response))
  } catch (error) {
    yield put(getStatesFail(error))
  }
}

function* fetchCities({ payload: stateID }) {
  try {
    const response = yield call(getCities, stateID)
    yield put(getCitiesSuccess(response))
  } catch (error) {
    yield put(getCitiesFail(error))
  }
}

function* fetchRoutes() {
  try {
    const response = yield call(getRoutes)
    yield put(getRoutesSuccess(response))
  } catch (error) {
    yield put(getRoutesFail(error))
  }
}

function* fetchKeyPoints() {
  try {
    const response = yield call(getKeyPoints)
    yield put(getKeyPointsSuccess(response))
  } catch (error) {
    yield put(getKeyPointsFail(error))
  }
}

function* fetchLocalAuthorities() {
  try {
    const response = yield call(getLocalAuthorities)
    yield put(getLocalAuthoritiesSuccess(response))
  } catch (error) {
    yield put(getLocalAuthoritiesFail(error))
  }
}

function* helperSaga() {
  yield takeEvery(GET_COUNTRIES, fetchCountries)
  yield takeEvery(GET_STATES, fetchStates)
  yield takeEvery(GET_CITIES, fetchCities)
  yield takeEvery(GET_ROUTES, fetchRoutes)
  yield takeEvery(GET_KEY_POINTS, fetchKeyPoints)
  yield takeEvery(GET_LOCAL_AUTHORITIES, fetchLocalAuthorities)
}

export default helperSaga
