import {
  GET_STAFFS_FAIL,
  GET_STAFFS_SUCCESS,
  DELETE_STAFF_SUCCESS,
  DELETE_STAFF_FAIL,
  ADD_STAFF_FAIL,
  ADD_STAFF_SUCCESS,
  GET_STAFF_DETAIL_SUCCESS,
  GET_STAFF_DETAIL_FAIL,
  UPDATE_STAFF_SUCCESS,
  UPDATE_STAFF_FAIL,
  UPDATE_STAFF_PHOTO_SUCCESS,
  UPDATE_STAFF_PHOTO_FAIL,
  RESET_STAFF_SUCCESS,
  GET_PAYRATES_SUCCESS,
  GET_PAYRATES_FAIL,
  DELETE_PAYRATE_SUCCESS,
  DELETE_PAYRATE_FAIL,
  ADD_PAYRATE_SUCCESS,
  ADD_PAYRATE_FAIL,
  GET_PAYRATE_DETAIL_SUCCESS,
  GET_PAYRATE_DETAIL_FAIL,
  UPDATE_PAYRATE_SUCCESS,
  UPDATE_PAYRATE_FAIL,
  GET_EMPLOYMENT_TYPES_SUCCESS,
  GET_EMPLOYMENT_TYPES_FAIL,
  GET_CLASSIFICATION_TYPES_SUCCESS,
  GET_CLASSIFICATION_TYPES_FAIL,
  GET_STAFF_AVAILABILITY_SUCCESS,
  GET_STAFF_AVAILABILITY_FAIL,
  ADD_STAFF_AVAILABILITY_SUCCESS,
  ADD_STAFF_AVAILABILITY_FAIL,
  GET_STAFF_PROFILE_SUCCESS,
  GET_STAFF_PROFILE_FAIL,
  ADD_STAFF_PROFILE_SUCCESS,
  ADD_STAFF_PROFILE_FAIL,
  GET_HAPPENING_TYPES_SUCCESS,
  GET_HAPPENING_TYPES_FAIL,
  GET_STAFF_HAPPENING_SUCCESS,
  GET_STAFF_HAPPENING_FAIL,
  ADD_STAFF_HAPPENING_SUCCESS,
  ADD_STAFF_HAPPENING_FAIL,
  GET_STAFF_HAPPENING_DETAIL_SUCCESS,
  GET_STAFF_HAPPENING_DETAIL_FAIL,
  UPDATE_STAFF_HAPPENING_SUCCESS,
  UPDATE_STAFF_HAPPENING_FAIL,
  DELETE_STAFF_HAPPENING_SUCCESS,
  DELETE_STAFF_HAPPENING_FAIL,
  GET_HOUR_TYPES_SUCCESS,
  GET_HOUR_TYPES_FAIL,
  GET_GENDERS_SUCCESS,
  GET_GENDERS_FAIL,
  GET_TITLES_SUCCESS,
  GET_TITLES_FAIL,
} from "./actionTypes"

const INIT_STATE = {
  staffs: [],
  payrates: [],
  payrate: {},
  employmentTypes: [],
  classificationTypes: [],
  staff: {},
  staffavailability: [],
  staffprofile: {},
  error: {},
  loading: true,
  happeningTypes: [],
  staffHappenings: [],
  staffHappening: {},
  hourTypes: [],
  genders: [],
  titles: [],
}

const Staff = (state = INIT_STATE, action) => {
  switch (action.type) {
    case GET_STAFFS_SUCCESS:
      return {
        ...state,
        staffs: action.payload,
        loading: true,
      }
    case GET_STAFFS_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case DELETE_STAFF_SUCCESS: {
      return {
        ...state,
        staffs: state.staffs.filter(staff => staff.UserID !== action.payload),
      }
    }
    case DELETE_STAFF_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case ADD_STAFF_SUCCESS:
      return {
        ...state,
        staffs: [...state.staffs, action.payload],
      }
    case ADD_STAFF_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case GET_STAFF_DETAIL_SUCCESS:
      return {
        ...state,
        staff: action.payload,
        loading: true,
      }

    case GET_STAFF_DETAIL_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case UPDATE_STAFF_SUCCESS:
      return {
        ...state,
        staffs: state.staffs.map(staff =>
          staff.UserID.toString() === action.payload.UserID.toString()
            ? { staff, ...action.payload }
            : staff
        ),
      }
    case UPDATE_STAFF_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case UPDATE_STAFF_PHOTO_SUCCESS:
      return {
        ...state,
      }

    case UPDATE_STAFF_PHOTO_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case RESET_STAFF_SUCCESS:
      return {
        ...state,
        staff: action.payload,
      }

    // payrate

    case GET_PAYRATES_SUCCESS:
      return {
        ...state,
        payrates: action.payload,
        loading: true,
      }
    case GET_PAYRATES_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case DELETE_PAYRATE_SUCCESS: {
      return {
        ...state,
        payrates: state.payrates.filter(
          payrate => payrate.PayRateID !== action.payload
        ),
      }
    }
    case DELETE_PAYRATE_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case ADD_PAYRATE_SUCCESS:
      return {
        ...state,
        payrates: action.payload,
      }
    case ADD_PAYRATE_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case GET_PAYRATE_DETAIL_SUCCESS:
      return {
        ...state,
        payrate: action.payload,
        loading: true,
      }

    case GET_PAYRATE_DETAIL_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case GET_EMPLOYMENT_TYPES_SUCCESS:
      return {
        ...state,
        employmentTypes: action.payload,
        loading: true,
      }
    case UPDATE_PAYRATE_SUCCESS:
      return {
        ...state,
        payrates: state.payrates.map(payrate =>
          payrate.PayRateID.toString() === action.payload.PayRateID.toString()
            ? { payrate, ...action.payload }
            : payrate
        ),
      }
    case UPDATE_PAYRATE_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case GET_EMPLOYMENT_TYPES_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case GET_CLASSIFICATION_TYPES_SUCCESS:
      return {
        ...state,
        classificationTypes: action.payload,
        loading: true,
      }
    case GET_CLASSIFICATION_TYPES_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case GET_STAFF_AVAILABILITY_SUCCESS:
      return {
        ...state,
        staffavailability: action.payload,
        loading: true,
      }
    case GET_STAFF_AVAILABILITY_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case ADD_STAFF_AVAILABILITY_SUCCESS:
      return {
        ...state,
        staffavailability: [...state.staffavailability, action.payload],
      }
    case ADD_STAFF_AVAILABILITY_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case GET_STAFF_PROFILE_SUCCESS:
      return {
        ...state,
        staffprofile: action.payload,
        loading: true,
      }
    case GET_STAFF_PROFILE_FAIL:
      return {
        ...state,
        staffprofile: {},
        error: action.payload,
      }
    case ADD_STAFF_PROFILE_SUCCESS:
      return {
        ...state,
        staffprofile: action.payload,
      }
    case ADD_STAFF_PROFILE_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case GET_HAPPENING_TYPES_SUCCESS:
      return {
        ...state,
        happeningTypes: action.payload,
        loading: true,
      }
    case GET_HAPPENING_TYPES_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case GET_STAFF_HAPPENING_SUCCESS:
      return {
        ...state,
        staffHappenings: action.payload,
        loading: true,
      }
    case GET_STAFF_HAPPENING_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case ADD_STAFF_HAPPENING_SUCCESS:
      return {
        ...state,
        staffHappenings: action.payload,
      }

    case ADD_STAFF_HAPPENING_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case GET_STAFF_HAPPENING_DETAIL_SUCCESS:
      return {
        ...state,
        staffHappening: action.payload,
        loading: true,
      }

    case GET_STAFF_HAPPENING_DETAIL_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case UPDATE_STAFF_HAPPENING_SUCCESS:
      return {
        ...state,
        staffHappenings: state.staffHappenings.map(staffHappening =>
          staffHappening.StaffHappeningID.toString() ===
          action.payload.StaffHappeningID.toString()
            ? { staffHappening, ...action.payload }
            : staffHappening
        ),
      }
    case UPDATE_STAFF_HAPPENING_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case DELETE_STAFF_HAPPENING_SUCCESS: {
      return {
        ...state,
        staffHappenings: state.staffHappenings.filter(
          staffHappening => staffHappening.StaffHappeningID !== action.payload
        ),
      }
    }
    case DELETE_STAFF_HAPPENING_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case GET_HOUR_TYPES_SUCCESS:
      return {
        ...state,
        hourTypes: action.payload,
        loading: true,
      }
    case GET_HOUR_TYPES_FAIL:
      return {
        ...state,
        error: action.payload,
      }

    case GET_GENDERS_SUCCESS:
      return {
        ...state,
        genders: action.payload,
        loading: true,
      }
    case GET_GENDERS_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    case GET_TITLES_SUCCESS:
      return {
        ...state,
        titles: action.payload,
        loading: true,
      }
    case GET_TITLES_FAIL:
      return {
        ...state,
        error: action.payload,
      }
    default:
      return state
  }
}

export default Staff
